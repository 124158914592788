.searchContainer {
    display: flex;
    align-items: center;
    user-select: none;
    position: relative;
    z-index: 1;
}

.backIcon {
    font-size: 24px;
    cursor: pointer;
    color: var(--primary-color);
    margin-right: 15px;
    transition: color 0.3s ease, transform 0.3s ease;
}

.backIcon:hover {
    color: var(--secondary-color);
    transform: translateX(-5px);
}

.searchBoxWrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.searchBox {
    width: 280px;
    padding: 10px 15px;
    padding-right: 40px;
    margin-right: 10px;
    height: 40px;
    border: 2px solid #ddd;
    border-radius: 20px;
    font-family: var(--font);
    font-size: 1rem;
}

@media (max-width: 420px) {
    .searchBox {
        width: 220px;
    }
}

.searchBox::placeholder {
    color: var(--input-placeholder);
    font-family: var(--font);
}

.searchBox:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 8px var(--primary-color);
    outline: none;
}

.searchIcon {
    position: absolute;
    right: 16px;
    cursor: pointer;
    color: var(--primary-color);
    font-size: 24px;
    transition: transform 0.3s ease, color 0.3s ease;
}

.searchIcon:hover {
    transform: scale(1.3);
    color: var(--secondary-color);
}

.searchSelect {
    border: 1px solid #ccc;
    margin-right: 10px;
    border-radius: 20px;
    padding: 8px;
    color: var(--text-color-dark);
    cursor: pointer;
    height: 40px;
    font-family: var(--font);
}

.searchSelect:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 8px var(--primary-color);
    outline: none;
}
