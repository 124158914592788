/* Container styles */
.container {
    position: fixed;
    background-color: var(--background-color);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    justify-items: center;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    border-bottom: 1px solid #ccc;
    height: var(--canvas-topbar-height);
}

/* Left Section */
.leftSection {
    grid-column: 1;
    justify-self: start;
    display: flex;
    align-items: center;
}

/* Middle Section */
.middleSection {
    grid-column: 2;
    display: flex;
    align-items: center;
    position: relative; /* For positioning the dropdown */
}

/* Right Section */
.rightSection {
    grid-column: 3;
    justify-self: end;
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.logo {
    cursor: pointer;
    height: 36px;
    margin-right: 6px;
    margin-left: 20px;
    margin-bottom: 1px;
}


/* Title Container */
.titleContainer {
    text-align: center;
    padding: 0 10px;
    margin-right: 10px;
    border-radius: 8px;
    overflow: hidden;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: auto;
    min-height: 50px;
}

/* Allow up to 2 lines of text, then truncate with ellipsis */
.titleContainer h1 {
    font-size: 18px;
    max-width: 200px; /* Adjust as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    word-wrap: break-word;
    margin: 0; /* Remove default margin */
}

/* Base item styles remain the same */
.item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin: 5px;
    background-color: #f5f5f5;
    color: #333;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s, box-shadow 0.3s;
    user-select: none;
    border: 1px solid transparent;
}

/* Active state for the toggle button */
.itemActive {
    background-color: var(--primary-color);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
    border-color: var(--secondary-color);
    color: #fff;
}

/* Inactive item hover styles */
.item:hover,
.item:focus {
    background-color: #d0d0d0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-color: #bbb;
}

/* Active item hover styles */
.itemActive:hover,
.itemActive:focus {
    background-color: var(--primary-color);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.5);
    border-color: var(--secondary-color);
    color: #fff;
}

/* Icon sizing */
.item svg {
    width: 24px;
    height: 24px;
}


/* Disabled item state */
.item[aria-disabled='true'] {
    background-color: #f0f0f0;
    color: #aaa;
    cursor: not-allowed;
    border-color: #ddd;
}

/* Hover and focus should not apply when disabled */
.item[aria-disabled='true']:hover,
.item[aria-disabled='true']:focus {
    background-color: #f0f0f0;
    box-shadow: none;
    border-color: #ddd;
}


/* Dropdown styles */
.dropdown {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--background-color);
    z-index: 9;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    overflow-x: auto;
    white-space: nowrap;
    padding: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

.dropdownItem {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid;
    border-radius: 8px;
    margin-right: 10px;
    background-color: #f1f1f1;
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 10px;
    overflow: hidden;
    word-wrap: break-word;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
    flex-shrink: 0;
}

.dropdownItem:hover {
    background-color: #e0e0e0;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.dropdownItem[data-type='group'] {
    text-overflow: ellipsis;
    white-space: normal;
    hyphens: auto;
    word-break: break-word;
}

/* Styles for the create group item */
.createGroupItem {
    border: none;
    background-color: transparent;
}

.createGroupItem .newGroupText {
    margin-top: 8px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: var(--text-color-dark);
}

.createGroupItem:hover {
    opacity: 0.8;
}

/* Plus icon styling */
.plusIcon {
    font-size: 32px;
    color: var(--primary-color);
}

/* Adjust icon sizes */
.item svg,
.dropdownItem svg {
    width: 32px;
    height: 32px;
}

/* Accessibility focus styles */
.item:focus,
.dropdownItem:focus {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
}


.tocContainer {
    position: fixed;
    background-color: var(--background-color);
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
}
