.overlay {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px;
    border-radius: 8px;
    width: auto;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000; /* Ensuring it's above the canvas */
  }
  
.item {
    cursor: pointer;
    margin-bottom: 5px;
    color: red; /* Error color for visibility */
}
  
.label {
    font-weight: bold;
}
  
.reason {
    margin-left: 5px;
}
  