/* EditSidebarKeys.module.css */

/* Existing Styles */

.keysContainer {
    padding: 0.5rem;
    background-color: var(--background-color);
    color: var(--text-color-dark);
    font-family: var(--font);
}

.keysContainer h3 {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: var(--primary-color);
}

.grantedKeysList {
    list-style: none;
    padding: 0;
    margin: 0 0 0.5rem 0;
}

.grantedKeyItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0.5rem;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    margin-bottom: 0.25rem;
    font-size: 0.9rem;
}

.grantedKeyItem span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.removeKeyButton {
    padding: 0.25rem 0.5rem;
    background-color: var(--alert-color);
    color: var(--text-color-light);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 0.8rem;
    transition: background-color var(--speed) ease;
}

.removeKeyButton:hover {
    background-color: #cc0000; /* Adjusted hover color for better compatibility */
}

.grantKeyButton {
    padding: 0.5rem;
    background-color: var(--primary-color);
    color: var(--text-color-light);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    width: 100%;
    font-size: 0.9rem;
    transition: background-color var(--speed) ease;
    margin-bottom: 0.5rem;
}

.grantKeyButton:hover {
    background-color: var(--primary-color-hover);
}

.availableKeysContainer {
    margin-top: 0.5rem;
    padding: 0.5rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
}

.availableKeysContainer h4 {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    color: var(--primary-color);
}

.availableKeysList {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 150px;
    overflow-y: auto;
}

.availableKeyItem {
    padding: 0.25rem 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    margin-bottom: 0.25rem;
    cursor: pointer;
    transition: background-color var(--speed) ease;
}

.availableKeyItem:hover {
    background-color: var(--light-hover-color);
}

.keyName {
    font-weight: bold;
    font-size: 0.9rem;
}

.keyDescription {
    font-size: 0.8rem;
    color: var(--text-color-dark);
}

.cancelGrantButton {
    margin-top: 0.5rem;
    padding: 0.25rem 0.5rem;
    background-color: var(--secondary-color);
    color: var(--text-color-dark);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    width: 100%;
    font-size: 0.8rem;
    transition: background-color var(--speed) ease;
}

.cancelGrantButton:hover {
    background-color: var(--secondary-color-hover);
}

/* New Styles for Visibility Conditions */

.visibilityContainer {
    margin-top: 1rem;
}

.activeMessage {
    margin-bottom: 2px;
}

.inactiveMessage {
    font-style: italic;
    color: var(--text-color-muted); /* Define this variable in your root CSS */
}


/* Visibility Condition Container */

.visibilityCondition {
    padding: 0.5rem;
    background-color: #eef7f7; /* Slightly different background to distinguish */
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
}

.visibilityCondition p {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 0.5rem 0;
    font-size: 0.9rem;
    color: var(--text-color-dark);
}

.visibilityCondition strong {
    color: var(--primary-color);
    margin-right: 0.25rem;
}

/* Inline visibility buttons */

/* Existing styles */

/* New styles for key name and buttons group */
.keyNameAndButtons {
    display: flex;
    align-items: center;
    margin-top: 0.15rem; /* Approximately 6px */
    margin-bottom: 0.6rem;
    gap: 0.5rem;
}

.keyNameAndButtons strong {
    color: var(--primary-color);
    margin: 0;
}

.visibilityButtonsInline {
    display: flex;
    gap: 0.25rem;
}

.changeKeyButton {
    padding: 0.15rem 0.3rem;
    background-color: var(--secondary-color);
    color: var(--text-color-dark);
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;
    font-size: 0.75rem;
    transition: background-color var(--speed) ease;
}

.changeKeyButton:hover {
    background-color: var(--secondary-color-hover);
}

/* Adjusted remove button for inline display */
.removeKeyButtonInline {
    padding: 0.15rem 0.3rem;
    background-color: var(--alert-color);
    color: var(--text-color-light);
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;
    font-size: 0.75rem;
    transition: background-color var(--speed) ease;
}

.removeKeyButtonInline:hover {
    background-color: #cc0000;
}

/* Inactive message styling */
.inactiveMessage {
    font-style: italic;
    color: var(--text-color-muted); /* Define this variable in your root CSS */
}


/* Switches Container */

.switchesContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.switchItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.switchItem label {
    font-size: 0.9rem;
    color: var(--text-color-dark);
}
