.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex-grow: 1;
}

.sidebarBodyContainer {
    display: flex;
    padding-left: 40px;
}

.sidebarBodyContainerMobile {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
    align-items: center;
}

.accountHeader {
    align-self: flex-start;
    padding-left: 40px;
    padding-top: 20px;
    margin-bottom: 10px;
}

.accountHeaderText {
    text-transform: capitalize;
}
