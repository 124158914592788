.securityContainer {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.changePasswordButton {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-size: 1rem;
}

.changePasswordButton:hover {
    background-color: var(--primary-color-hover);
}

.passwordForm {
    margin-top: 1.5rem;
}

.formGroup {
    margin-bottom: 1.5rem;
}

.formGroup label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-color-dark);
    font-weight: 500;
}

.formGroup input {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s ease;
}

.formGroup input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(0,102,102,0.1);
}

.buttonGroup {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.submitButton {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    flex: 1;
}

.submitButton:hover:not(:disabled) {
    background-color: var(--primary-color-hover);
}

.cancelButton {
    background-color: var(--secondary-color);
    color: var(--text-color-dark);
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    flex: 1;
}

.cancelButton:hover {
    background-color: var(--secondary-color-hover);
}

.errorMessage {
    color: var(--alert-color);
    padding: 0.8rem;
    background-color: #fee;
    border-radius: 4px;
    margin: 1rem 0;
}

.successMessage {
    color: var(--primary-color);
    padding: 0.8rem;
    background-color: #e6f4f4;
    border-radius: 4px;
    margin: 1rem 0;
}

button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Add these new styles */
.section {
    margin-bottom: 3rem;
    padding: 1.5rem;
    background-color: var(--secondary-color-hover-notifications-gray);
    border-radius: 8px;
}

.section h3 {
    color: var(--primary-color);
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.usernameInputContainer {
    position: relative;
}

.checkingIndicator {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-color-dark);
    font-size: 0.875rem;
}

.available {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--primary-color);
    font-size: 0.875rem;
    font-weight: bold;
}

.taken {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--alert-color);
    font-size: 0.875rem;
    font-weight: bold;
}

.changeButton {
    background-color: var(--secondary-color);
    color: var(--text-color-dark);
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.changeButton:hover {
    background-color: var(--secondary-color-hover);
}
