.container {
    display: flex;
    min-height: calc(100vh - var(--navbar-height));
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
}

.content {
    flex-grow: 1;
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}