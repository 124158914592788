.classroomAbout {
    background-color: var(--background-color);
    border-radius: 8px;
    padding: 30px 80px;
    overflow-y: auto;
}

.aboutContent {
    font-size: 16px;
    line-height: 1.6;
    color: #333333;
}

.buttonGroup {
    margin-top: 15px;
}

.saveButton,
.cancelButton,
.editButton {
    padding: 10px 20px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}

.saveButton {
    background-color: var(--secondary-color);
    color: var(--text-color-dark);
}

.cancelButton {
    background-color: var(--accent-color);
    color: var(--text-color-light);
}

.editButton {
    background-color: var(--secondary-color);
    color: var(--text-color-dark);
    margin-top: 15px;
}

.saveButton:disabled,
.cancelButton:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.saveButton:hover:not(:disabled) {
    background-color: var(--secondary-color-hover);
}

.cancelButton:hover:not(:disabled) {
    background-color: var(--accent-color-hover);
}

.editButton:hover {
    background-color: var(--secondary-color-hover);
}
