.editEdge {
    padding: 0px 16px;
    font-family: var(--font);
}

.field {
    margin-bottom: 16px;
}

.field label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.field input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.buttons button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.deleteButton {
    background-color: var(--alert-color);
    color: white;
}

.deleteButton:hover {
    background-color: darkred;
}
