/* CreateSituation.module.css */

.container {
    padding: 10px;
}

.nodeTypes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding-top: 20px;
}
