.spinner {
    width: 1rem;
    height: 1rem;
    border: 2px solid var(--secondary-color);
    border-top: 2px solid var(--primary-color);
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
