/* ChoicesBody.module.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 80px;
}

.choice {
    max-width: 600px;
    width: 100%; /* Each choice takes up 40% of the parent container's width */
    margin: 10px 0; /* Vertical margin for spacing */
    border-radius: 8px;
    background-color: #e2e8f0; /* Light gray background */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-sizing: border-box;
    min-height: 50px;
    display: flex; /* Use Flexbox for vertical centering */
    align-items: center; /* Center items vertically */
    justify-content: flex-start; /* Center items horizontally */
    padding: 20px 20px; /* Padding for top and bottom */
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    min-width: 200px;
}

.disabledChoice {
    pointer-events: none;
    opacity: 0.5; /* or whatever you prefer */
}


.choice p {
    margin-bottom: 0px;
}


.choice:hover {
    background-color: #cbd5e0; /* Darker gray on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}


.choice ul,
.choice ol {
    margin: 1em 0;
    padding-left: 40px;
}

.choice li {
    margin: 0.5em 0;
    list-style-type: disc;
}

