.situationsContainer {
    background-color: var(--background-color);
    padding-top: 30px;
}

.situationItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--background-color);
    margin-bottom: 0.5rem;
    padding: 0.625rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
}

.situationItem:hover {
    background-color: var(--secondary-color);
    box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.15);
}

.situationTitle {
    font-size: 1rem;
    font-weight: 500;
    color: var(--text-color-dark);
    white-space: normal;
    word-wrap: break-word;
    flex: 1;
    margin-right: 0.625rem;
}

.removeButton {
    background-color: var(--alert-color);
    border: none;
    cursor: pointer;
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;
}

.removeButton:hover {
    transform: scale(1.1);
}

.minusIcon {
    color: var(--text-color-light);
    font-size: 1rem;
    line-height: 1;
}

.noSituationsText {
    text-align: center;
    color: var(--input-placeholder);
    font-size: 1rem;
    padding: 1.25rem;
}
