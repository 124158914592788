.peopleContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 20%;
    padding-left: 20%;
    padding-top: 4%;
}

.categoryContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #e0e0e0;
}

.list {
    margin-top: 10px;
    list-style-type: none;
    padding: 0;
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
}

.inviteIcon {
    cursor: pointer;
    width: 30px;
}

.profileIcon {
    width: 40px;
    border-radius: 50%;
    background-color: #f5f5f5;
}

.profileInfo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.actions {
    display: flex;
    gap: 10px;
}

.changeRoleButton {
    background-color: #4caf50; /* Green */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.changeRoleButton:hover {
    background-color: #45a049;
}

.removeButton {
    background-color: #f44336; /* Red */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.removeButton:hover {
    background-color: #e53935;
}
