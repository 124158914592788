.container {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    min-height: 100vh;
    width: 100%;
    margin-top: 0;
}

.heroSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20px;
    margin-top: 50px;
    width: 100%;
    height: 400px;
    box-sizing: border-box;
    color: white;
    background-image: url('../../../../images/Vector_Drawing.svg');
    background-size: 99vw;
    background-position: center;
    background-repeat: no-repeat; 
}

@media (max-width: 900px) {
    .heroSection {
        background-image: none;
    }
  
}


.headerText
{
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 10px;
}

.heroText{
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 10px;
}

.searchBar {
    width: 280px;
    padding: 10px 15px;
    padding-right: 40px;
    margin-right: 10px;
    height: 40px;
    border: 2px solid #ddd;
    border-radius: 20px;
    font-family: var(--font);
    font-size: 1rem;
    
}

.searchBar:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 8px var(--primary-color);
    outline: none;
}

.searchButton {
    position: absolute;
    transform: translateY(-50%);
    border: none;
    background: none;
    cursor: pointer;
    padding-top: 15px;

}

.searchIcon {
    position: absolute;
    right: 16px;
    cursor: pointer;
    color: var(--primary-color);
    font-size: 24px;
    transition: transform 0.3s ease, color 0.3s ease;
}

.searchIcon:hover {
    transform: scale(1.2);
    color: var(--secondary-color);
}

.sectionName {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    color: black;
}

.sectionNameContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    /* margin: 10px 0; */
    padding: 0 20px;
}



