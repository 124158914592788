/* AuthForm.module.css */
.container {
    display: flex;
    flex-direction: row;
    height: 600px;
    width: 60%;
    justify-self: center;
    border: 1px solid #333;
    border-radius: 10px;
    margin-bottom: 58px;
    overflow: hidden;
}

.leftHalf,
.rightHalf {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 300px;
}

/* Background colors for login mode */
.loginLeft {
    background-color: var(--secondary-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.loginRight {
    background-color: var(--primary-color);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* Background colors for register mode */
.registerLeft {
    background-color: var(--primary-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.registerRight {
    background-color: var(--secondary-color);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
