.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 350px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.inputField {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.situationsList {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
}

.situationItem {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.selectedSituation {
    background-color: var(--secondary-color);
    color: #fff;
}

.buttonGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.saveButton {
    background-color: var(--primary-color);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancelButton {
    background-color: #dc3545;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.saveButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
