.container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    align-items: center;
    margin-bottom: 10vh;
    margin-top: 10vh;
  width: 80%;
}

.myMasonryGrid {
    display: flex;
    width: 100%;
    gap: 16px;
}

.myMasonryGridColumn {
    background-clip: padding-box;
    display: flex;
    flex-direction: column;
}

.myMasonryGridColumn>* {
    margin-bottom: 16px;
}

.searchInput {
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    max-width: 600px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
