.container {
    padding:  20px 40px;
    overflow-y: auto;
    flex-grow: 1;
}

.classroomGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Dynamic columns */
    gap: 20px;
    justify-content: center;
    padding-bottom: 20px;
}

.searchBar {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.classroomList {
    padding: 10px;
    list-style-type: none;
}

.classroomList li {
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    font-size: 16px;
}

.classroomList li a {
    text-decoration: none;
    color: inherit;
}

.classroomList li:last-child {
    border-bottom: none;
}
