
.container {
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: var(--background-color);
    height: 100%;
    width: 100%;
    flex-grow: 1;
}

.header {
    position: fixed;
    top: var(--navbar-height);
    width: var(--toc-width);
    left: 20px;
    background: none;
    border: none;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-items: flex-start;
}

.tocTitle {
    font-size: 20px;
    transform: translateY(-4px); /* Shifts the title up by 4 pixels */
}

@media (max-width: 810px) {
    .tocTitle {
        display: none;
    }
}


.tocButton {
    cursor: pointer;
    font-size: 24px;
    color: var(--primary-color);
}

.tocButton:hover {
    color: var(--primary-color-hover);
}


.restartButton {
    position: fixed;
    top: var(--navbar-height);
    right: 30px;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease;
    color: var(--primary-color);
}

  
.restartButton:hover {
    transform: scale(1.1);
    background-color: transparent;
}

.exitPreview {
    position: fixed;
    top: var(--navbar-height);
    right: 90px;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease;
    color: var(--primary-color);
    background: none;
    border: none;
    outline: none;
}

.exitPreview:hover {
    transform: scale(1.1);
    background-color: transparent;
}



.previewButton {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    transition: transform 0.3s ease;
    z-index: 9;
    padding: 0.75rem 1.5rem;
    background-color: var(--primary-color); /* blue color */
    color: var(--text-color-light);
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* subtle shadow */
    transition: background-color var(--speed) ease;
}

.previewButton:hover {
    background-color: var(--primary-color-hover); /* darker blue on hover */
}
