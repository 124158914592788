.sidebarContainer {
    width: 300px;
    min-width: 300px;
    background-color: var(--background-color);
    border-right: 1px solid #dfe3e8;
    overflow-y: auto;
}

/* Sidebar options (home button) */
.sidebarOptions {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}

/* Section container for My Classrooms and Joined Classrooms */
.sectionContainer {
    margin-bottom: 20px;
}

/* Section title */
.sectionTitle {
    font-size: 14px;
    font-weight: 600;
    color: #666;
    padding: 10px 20px;
    text-transform: uppercase;
}

/* Divider between My Classrooms and Joined Classrooms */
.divider {
    height: 1px;
    background-color: #e0e0e0;
    margin: 10px 0;
}

/* Classroom list container */
.sidebarClassrooms {
    display: flex;
    flex-direction: column;
}

/* Link styling */
.linkStyle {
    text-decoration: none;
    color: inherit;
}

/* Sidebar classroom item */
.sidebarClassroomItem {
    padding: 12px 20px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    font-weight: 500;
    color: #333;
    background-color: #ffffff;
    border-radius: 8px;
    margin: 8px 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

/* Selected classroom item */
.sidebarClassroomItemSelected {
    background-color: var(--secondary-color);
    font-weight: 600;
    color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Hover effect for unselected classroom item */
.sidebarClassroomItem:not(.sidebarClassroomItemSelected):hover {
    background-color: rgba(0, 123, 255, 0.1);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
}

/* Icon styling */
.icon {
    margin-right: 10px;
    vertical-align: middle;
}

/* Smooth transitions */
.sidebarClassroomItem, .sidebarClassroomItemSelected {
    transition: background-color 0.3s, box-shadow 0.3s;
}
