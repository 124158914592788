.tagSpan {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 8px;
}

.tagDiv {
    background-color: var(--accent-color-light);
    border-radius: 12px;
    padding: 4px 8px;
    cursor: pointer;
    position: relative;
}

.tagText {
    font-size: 14px;
    color: var(--text-color-dark);
    white-space: nowrap;
}

/* Extra tags dropdown styling */
.extraTagsDropdown {
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 4px;
    border-radius: 4px;
}

.dropdownTag {
    padding: 4px 8px;
    cursor: pointer;
    white-space: nowrap;
}

.dropdownTag:hover {
    background-color: #f5f5f5;
}
