/* SituationsAndGroups.module.css */

.container {
    padding: 10px;
    overflow-y: auto;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.createGroupButton {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: var(--primary-color);
    color: var(--text-color-light);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color var(--speed) ease;
    margin-top: 0.6rem;
    margin-bottom: 2.5rem;
    font-size: 0.9rem;
    width: 100%;
    max-width: 200px;
}

.createButton:hover {
    background-color: var(--primary-color-hover);
}

.list {
    max-height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
}

.list::-webkit-scrollbar {
    width: 8px;
}

.groupTitle {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    user-select: none;
}

.selectedGroup {
    background-color: var(--secondary-color);
    font-weight: bold;
}

.groupTitle span {
    margin-right: 5px;
}

.situationItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    position: relative;
    padding: 5px 10px;
}

/* Bullet point */
.situationItem::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--bullet-color, #000);
    margin-right: 10px;
    flex-shrink: 0;
}

.groupedSituationItem {
    padding-left: 30px;
}

.selectedSituation {
    background-color: var(--secondary-color);
    font-weight: bold;
}

/* Add styles for the situation list within a group or ungrouped */
.situationList {
    padding-left: 20px;
    transition: background-color 0.2s ease;
}

/* Highlight droppable area when dragging over */
.isDraggingOver {
    background-color: #e0f7fa; /* Light blue background when dragging over */
}

/* Style for the ungrouped title */
.ungroupedTitle {
    margin-top: 20px;
    font-weight: bold;
}
