/* Modal overlay */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
}

/* Modal content */
.modalContent {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    animation: slideDown 0.3s ease-out;
}

/* Animations */
@keyframes fadeIn {
    from {
        background: rgba(0, 0, 0, 0);
    }
    to {
        background: rgba(0, 0, 0, 0.5);
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Modal title */
.modalTitle {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

/* Modal description */
.modalDescription {
    font-size: 16px;
    margin-bottom: 20px;
    color: #555;
}

/* Button group */
.buttonGroup {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

/* Button base styles */
.buttonBase {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex: 1;
}

/* Yes button (Primary color) */
.yesButton {
    background-color: var(--primary-color);
    color: #fff;
}

.yesButton:hover {
    background-color: var(--primary-color-hover);
}

/* No button (Accent color) */
.noButton {
    background-color: var(--accent-color);
    color: #fff;
}

.noButton:hover {
    background-color: var(--accent-color-hover);
}

/* Accessibility focus styles */
.buttonBase:focus {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
}

/* Responsive adjustments */
@media (max-width: 480px) {
    .modalContent {
        width: 90%;
        padding: 20px;
    }

    .buttonGroup {
        flex-direction: column;
    }

    .buttonBase {
        width: 100%;
    }
}
