/* EditAnnouncementModal.module.css */

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modalContent {
    background-color: var(--background-color);
    width: 500px;
    max-height: 80vh;
    overflow-y: auto;
    padding: 20px;
    border-radius: 5px;
}

.buttonGroup {
    margin-top: 15px;
    text-align: right;
}

.saveButton,
.cancelButton {
    padding: 10px 20px;
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}

.saveButton {
    background-color: var(--primary-color);
    color: var(--text-color-light);
}

.cancelButton {
    background-color: var(--alert-color);
    color: var(--text-color-light);
}

.saveButton:hover {
    background-color: var(--primary-color-hover);
}

.cancelButton:hover {
    background-color: #cc0000; /* Darker shade of red for hover effect */
}
