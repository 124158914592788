.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
}

.tabsWrapper {
    width: 100%;
    /* Override any vertical centering from the parent */
    align-self: flex-start;
    /* Optionally add a top margin if needed */
    margin-top: 0;
    background-color: var(--background-color);
}


.recommendedTags {
    width: 50vw;
    margin: auto;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
