/* Modal overlay */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
}

/* Modal content */
.modalContent {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    width: 500px;
    max-width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    animation: slideDown 0.3s ease-out;
}

/* Animations */
@keyframes fadeIn {
    from {
        background: rgba(0, 0, 0, 0);
    }
    to {
        background: rgba(0, 0, 0, 0.5);
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Modal title */
.modalTitle {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

/* Textarea */
.textarea {
    width: 100%;
    height: 150px;
    margin-top: 10px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    resize: vertical;
    font-family: inherit;
    transition: border-color 0.2s ease-in-out;
}

.textarea:focus {
    border-color: var(--primary-color);
    outline: none;
}

/* Button container */
.buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

/* Button base styles */
.buttonBase {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Close button (Accent color) */
.closeButton {
    background-color: var(--accent-color);
    color: #fff;
}

.closeButton:hover {
    background-color: var(--accent-color-hover);
}

/* Send button (Primary color) */
.sendButton {
    background-color: var(--primary-color);
    color: #fff;
}

.sendButton:hover {
    background-color: var(--primary-color-hover);
}

/* Accessibility focus styles */
.buttonBase:focus {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
}

/* Responsive adjustments */
@media (max-width: 480px) {
    .modalContent {
        width: 90%;
        padding: 20px;
    }

    .buttonContainer {
        flex-direction: column;
        align-items: stretch;
    }

    .buttonBase {
        width: 100%;
    }
}
