.dropdown {
    position: absolute;
    top: var(--navbar-height);
    right: 0;
    background-color: var(--background-color);
    z-index: 10;
    width: 320px;
    border-radius: 12px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
    max-height: 500px;
    overflow-y: auto;
    transition: opacity 0.3s ease, transform 0.3s ease;
    animation: slideDown 0.3s ease-out;
    padding-top: 8px;
    padding-bottom: 8px;
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.header {
    text-align: center;
    margin-bottom: 8px;
}

.header h2 {
    font-size: 18px;
    color: #333;
    margin: 0;
}

.notificationItem {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    padding: 12px;
    background-color: var(--background-color);
    color: var(--primary-color);
    transition: background-color 0.3s ease, transform 0.2s ease;
    cursor: pointer;
}

.notificationItem:hover {
    background-color: #f2f2f2;
}

.unseen {
    background-color: #e6f7f7;
}

.unseen:hover {
    background-color: #d0eeee;
}

/* Icon container */
.iconContainer {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
}

/* Text container for the notification text and actions */
.textContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: var(--primary-color);
}

.blueDot {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 50%;
}

.notificationActions {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.acceptButton,
.ignoreButton {
    background-color: #555;
    color: #fff;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
}

.acceptButton:hover {
    background-color: #4caf50;
}

.ignoreButton:hover {
    background-color: #f44336;
}

.noNotifications {
    padding: 12px;
    text-align: center;
    color: #999;
    font-size: 14px;
}

.loadMoreContainer {
    text-align: center;
    margin-top: 8px;
}

.loadMoreButton {
    width: 100%;
    background-color: var(--background-color);
    color: var(--primary-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
    padding: 8px;
    font-size: 14px;
}

.loadMoreButton:hover {
    background-color: #f2f2f2;
}
