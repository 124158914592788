/* SetOrder.module.css */

.container {
    padding: 10px;
}

.situationsList {
    padding: 0;
    margin: 20px 0;
    list-style: none;
    max-height: calc(100vh - 200px); /* Adjust as needed */
    padding-bottom: 20px; /* Added padding at the bottom */
}

.situationItem {
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.situationTitle {
    flex-grow: 1;
    margin-right: 10px;
    word-wrap: break-word;
    word-break: break-word;
}

.positionNumber {
    flex-shrink: 0;
    font-weight: bold;
    width: 30px; /* Fixed width to prevent resizing */
    text-align: center;
}

.situationItemDragging {
    background-color: #e0e0e0;
}

/* Optional Close Button Styles */
.closeButton {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}

.closeButton:hover {
    background-color: #0056b3;
}

.promptContainer {
    text-align: center;
}

.yesButton,
.noButton {
    margin: 10px;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    color: white;
}

.yesButton {
    background-color: #28a745;
}

.yesButton:hover {
    background-color: #218838;
}

.noButton {
    background-color: #dc3545;
}

.noButton:hover {
    background-color: #c82333;
}
