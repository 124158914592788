.searchSelect {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px;
    color: var(--text-color-dark);
    cursor: pointer;
    height: 38px;
    z-index: 10;
    width: 300px;
    align-self: center;
}


.searchSelect:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 5px var(--primary-color);
    outline: none;
}