/* styles/CategoryModal.module.css */

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 600px; /* Make the modal wider */
    max-height: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 600px;
}

.topSection {
    display: flex;
    gap: 20px; /* Add gap between left and right halves */
    flex: 1; /* Make it take available height */
}

.leftHalf, .rightHalf {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1; /* Ensure equal width */
}

.leftHalf {
    padding-right: 10px; /* Add some padding to separate the halves */
}

.rightHalf {
    padding-left: 10px; /* Add some padding to separate the halves */
}

.inputField {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.colorPicker {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
}

.scenarioList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 400px;
    overflow-y: auto;
}

.scenarioItem {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border: 2px solid transparent;
    padding: 5px;
}

.scenarioItem.selected {
    border-color: #007bff;
}

.scenarioImage {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
}

.bottomSection {
    display: flex;
    justify-content: space-between; /* Align buttons left-right */
    width: 100%;
}

.saveButton, .closeButton, .deleteButton {
    width: 48%; /* Each button takes 48% width to allow space between them */
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.saveButton {
    background-color: #28a745;
    color: white;
}

.closeButton {
    background-color: #dc3545;
    color: white;
}

.deleteButton {
    background-color: #ffc107; /* Yellow color for delete button */
    color: white;
}
