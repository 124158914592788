/* ShareModal.module.css */
.overlay {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    z-index: 11;
}

.modal {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 40px;
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.exitModal {
    position: absolute;
    top: 20px;
    right: 23px;
    color: black;
    cursor: pointer;
    font-size: 26px;
    font-weight: bold;
}

.header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.body {
    width: 100%;
    text-align: center;
}

.url {
    flex: 1;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.success {
    color: green;
    margin-top: 10px;
}

.icon {
    cursor: pointer;
    font-size: 24px;
    margin-left: 10px;
}

.instructions {
    width: 100%;
    margin-bottom: 10px;
}

.shareContainer {
    display: flex;
    align-items: center;
    width: 100%;
}
