.navbarContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--navbar-height);
    background-color: var(--navbar-color);
    color: var(--text-color-dark);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    z-index: 10;
}

.logoContainer {
    display: flex;
    align-items: center;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .logoContainer {
        padding: 5px;
        /* Reduced padding for smaller screens */
        flex-direction: column;
        /* Stack items vertically on small screens */
        align-items: flex-start;
        /* Align items to the start of the flex container */
    }
}

.logo {
    cursor: pointer;
    height: 36px;
    margin-right: 6px;
    margin-left: 10px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .logo {
        height: 24px;
        /* Reduced height for smaller screens */
        margin-right: 4px;
        /* Slightly smaller margin */
        margin-left: 5px;
        /* Reduced margin on the left for tight spaces */
    }
}

.navbarSearchContainer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
}

.navbarItemContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;
    /* Center the items horizontally */
}

.navbarItem {
    width: 70px;
    /* Set a fixed width suitable for all labels */
    margin: 0 8px;
    /* Adjust margins to fit total width */
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary-color);
    position: relative;
    user-select: none;
    transition: transform 0.2s ease, color 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    /* Hide overflowing content */
}

/* Shrink icon size slightly to accommodate label */
.icon {
    font-size: 20px;
    /* Slightly smaller */
}


.iconLabel {
    font-size: 0.75rem;
    /* Adjust font size for labels */
    margin-top: 4px;
    /* Space between icon and label */
    color: var(--text-color-dark);
    font-weight: normal;
    white-space: nowrap;
    /* Prevent text from wrapping */
    text-overflow: ellipsis;
    /* Truncate text with ellipsis if it overflows */
    overflow: hidden;
    /* Hide overflowing text */
}

/* Notification badge styling remains unchanged */
.notificationBadge {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 0.8rem;
    position: absolute;
    top: -5px;
    right: 10px;
    /* Adjusted to position within fixed width */
}

/* Hover effect for icons */
.navbarItem:hover {
    color: var(--primary-color-hover);
    transform: scale(1.05);
}

@media (max-width: 900px) {
    .navbarItem {
        width: auto;
        /* Allow width to adjust on smaller screens */
        margin: 0 12px;
    }

    .iconLabel {
        display: none;
        /* Hide labels to save space on mobile */
    }
}


.login,
.signup {
    padding: 8px 16px;
    margin: 0 5px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    font-weight: bold;
    font: var(--font);
}

.login {
    background-color: var(--primary-color);
    color: var(--text-color-light);
    border: 2px solid var(--primary-color);
}

.signup {
    background-color: transparent;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
}

.icon {
    font-size: 24px;
    cursor: pointer;
    color: var(--primary-color);
}

.expandedSearchContainer {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--navbar-height);
    z-index: 999;
}
