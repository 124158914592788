/* Modal overlay */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal content */
.modalContent {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    width: 500px;
    max-width: 90%;
    position: relative;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}


/* Modal title */
.modalTitle {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
}

/* Button base styles */
.buttonBase {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: inline-block;
    text-align: center;
}

/* Upload button */
.uploadButton {
    background-color: var(--primary-color);
    color: #fff;
    width: 100%;
    margin-bottom: 20px;
}

.uploadButton:hover {
    background-color: var(--primary-color-hover);
}

.errorMessage {
    color: #dc3545;
    margin-top: 10px;
    text-align: center;
}

/* Preview container */
.previewContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
}


/* Preview image */
.previewImage {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Preview media */
.previewMedia {
    width: 100%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Button group */
.buttonGroup {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

/* Save button */
.saveButton {
    background-color: var(--primary-color);
    color: #fff;
    flex: 1;
}

.saveButton:hover {
    background-color: var(--primary-color-hover);
}

.saveButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Cancel button */
.cancelButton {
    background-color: #dc3545;
    color: #fff;
    flex: 1;
}

.cancelButton:hover {
    background-color: #c82333;
}

/* Accessibility focus styles */
.buttonBase:focus {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
}

@media (max-width: 480px) {
    .modalContent {
        width: 90%;
        padding: 20px;
    }

    .buttonGroup {
        flex-direction: column;
    }

    .buttonGroup .buttonBase {
        width: 100%;
    }

    .closeButton {
        top: 10px;
        right: 10px;
    }
}

