@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');


.container {
    display: flex;
    flex-direction: column;
    padding: 50px;
    overflow-y: auto;
}


.log {
    position: relative;
    margin-bottom: 20px;
    /* Remove top and bottom margin to eliminate vertical space */
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 8px 20px rgba(0, 102, 102, 0.125);
    transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
    padding-right: 80px;
    font-family: 'Poppins', sans-serif;
    max-width: 900px;

}

.logMobile {
    position: relative;
    margin: 0;
    /* Remove top and bottom margin to eliminate vertical space */
    margin-right: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 8px 20px rgba(0, 102, 102, 0.125);
    transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
    font-family: 'Poppins', sans-serif;
}

.log:hover {
    box-shadow: 0 12px 25px rgba(0, 102, 102, 0.125);
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.table th,
.table td {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 12px;
    text-align: left;
    font-size: 14px;
    color: #333;
}

.table th {
    background-color: rgba(0, 0, 0, 0.05);
    font-weight: 600;
}

.log h3 {
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;
    transition: color 0.3s, transform 0.3s;
    color: rgb(0, 102, 102);
    font-size: 20px;
    font-weight: 600;
}

.log h3:hover {
    color: rgb(178, 216, 216);
    transform: translateY(-2px);
}

.deleteLog {
    position: absolute;
    top: 20px;
    right: 23px;
    color: rgb(0, 102, 102);
    cursor: pointer;
    font-size: 26px;
    font-weight: bold;
    transition: color 0.3s;
}

.deleteLog:hover {
    color: rgb(178, 216, 216);
}

.searchBar {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: left;
}

.searchInput {
    padding: 12px 20px;
    width: 100%;
    max-width: 600px;
    border: 2px solid rgb(105, 157, 157);
    border-radius: 25px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.searchInput:focus {
    border-color: rgb(0, 255, 255);
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.3);
    outline: none;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.pagination button {
    margin: 0 5px;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    background-color: rgb(178, 216, 216);
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.3s;
}

.pagination button:hover {
    background-color: rgb(105, 157, 157);
    transform: translateY(-2px);
}

.pagination .activePage {
    background-color: rgb(105, 157, 157);
    transform: translateY(-2px);
}

.error {
    color: rgb(0, 102, 102);
    text-align: center;
}
