/* ScenarioTitlePageTags.module.css */
.wrapper {
    width: 100%;
    margin-top: 20px;
}

.title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.title h2 {
    font-size: 1.5em;
    font-weight: 600;
}

.content p {
    font-size: 0.9em;
    color: #666;
}

.tagList {
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;
    border: 1px solid #a6a6a6;
    list-style: none;
    padding: 0px;
}

.tagItem {
    display: flex;
    align-items: center;
    color: #333;
    background: #f2f2f2;
    border-radius: 5px;
    border: 1px solid #e3e1e1;
    font-size: 0.9em;
    padding: 0px;
    margin-left: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 3px;
}

.removeIcon {
    margin-left: 8px;
    font-size: 1em;
    cursor: pointer;
    color: #808080;
}

.inputItem {
    flex: 1;
}

.input {
    width: 100%;
    padding: 0px 8px;
    border: none !important;
    outline: none;
    font-size: 1em;
    background: transparent;
    height: 48px;
}

.input:focus:not([type="checkbox"]) {
    border-color: transparent;
    box-shadow: 0 0 0px transparent;
    padding: 0px 8px;
    height: 48px;
    font-size: 1em;
  }

.details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.details p {
    font-size: 0.9em;
    color: #666;
}

.details span {
    font-weight: bold;
}

.clearButton {
    background-color: var(--accent-color);
    color: var(--text-color-light);
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.clearButton:hover {
    background-color: var(--primary-color-hover);
}
