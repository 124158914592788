.profile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0;
    width: 100%;
}

@media (max-width: 768px) {
    .profile {
        align-items: center;
    }
}

.editButton {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: var(--secondary-color);
    color: var(--text-color-dark);
    cursor: pointer;
    margin-bottom: 30px;
}

/* New class specifically for the profile picture */
.profilePicture {
    width: 200px;
    height: 200px;
    border-radius: 50%; /* Makes the profile picture circular */
    margin-bottom: 20px;
    user-select: none;
    -webkit-user-drag: none;
}

/* Removed border-radius from this selector */
.profile img {
    width: 100%;
    max-width: 380px;
    height: auto;
}

.profile h5 {
    margin: 10px 0;
}

.profile p {
    margin: 10px 0;
}

.quillContainer {
    width: 75%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.editBio {
    margin: 10px 0;
    flex: 1;
    width: 100%;
    overflow-y: auto;
    resize: none;
    min-height: 150px;
    height: 350px;
    /* Optional: to ensure a minimum height */
}

.buttonContainer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.saveButton,
.cancelButton {
    margin: 10px 10px 0 0;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.saveButton {
    background-color: var(--secondary-color);
    color: var(--text-color-dark);
    margin-left: 30px;
}

.cancelButton {
    background-color: lightgray;
    color: black;
}
