/* UserSubmittedBody.module.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    padding: 20px;
    box-sizing: border-box;
}

.tabs {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}

.tab {
    flex: 1;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.tab:hover {
    background-color: #eee;
}

.active {
    background-color: #fff;
    border-bottom: 2px solid #007bff;
    font-weight: bold;
}

.tabContent {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.recordAudio {
    font-size: 18px;
    font-weight: bold;
    color: #555;
    margin-bottom: 20px;
}

.buttonContainer { 
    display: flex;
    flex-direction: row;
}

.button {
    padding: 0.75rem 1.5rem;
    background-color: #3182ce; /* blue color */
    color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* subtle shadow */
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #2b6cb0; /* darker blue on hover */
}

.button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5); /* focus ring */
}
