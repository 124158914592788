.container {
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
    background-color: var(--background-color);
}

.header {
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 2rem;
    font-size: 2.5rem;
}

.plansContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.planCard {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    border: 1px solid var(--secondary-color);
}

.planCard:hover {
    transform: translateY(-5px);
}

.planContent {
    padding: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.planName {
    color: var(--primary-color);
    margin-bottom: 1rem;
    font-size: 1.5rem;
    text-align: center;
}

.price {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-color-dark);
    text-align: center;
    margin-bottom: 1.5rem;
}

.featuresList {
    list-style: none;
    padding: 0;
    margin: 0 0 2rem 0;
    flex-grow: 1;
}

.featureItem {
    padding: 0.8rem 0;
    border-bottom: 1px solid var(--secondary-color-hover);
    color: var(--text-color-dark);
    display: flex;
    align-items: center;
}

.featureItem:last-child {
    border-bottom: none;
}

.subscribeButton {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    width: 100%;
    margin-top: auto;
}

.subscribeButton:hover {
    background-color: var(--primary-color-hover);
}

/* Highlight recommended plan */
.planCard:nth-child(2) {
    border: 2px solid var(--accent-color);
    transform: scale(1.05);
}

.planCard:nth-child(2) .subscribeButton {
    background-color: var(--accent-color);
}

.planCard:nth-child(2) .subscribeButton:hover {
    background-color: var(--accent-color-hover);
}

@media (max-width: 768px) {
    .plansContainer {
        grid-template-columns: 1fr;
        max-width: 500px;
    }
    
    .planCard:nth-child(2) {
        transform: scale(1);
    }
}