.sidebar {
    width: 250px;
    padding: 1rem;
    background-color: var(--secondary-color-hover-notifications-gray);
    border-radius: 8px;
    margin-right: 2rem;
}

.tabButton {
    width: 100%;
    padding: 1rem;
    margin: 0.5rem 0;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    border-radius: 6px;
    font-family: var(--font);
    color: var(--text-color-dark);
    transition: all 0.2s ease;
}

.tabButton:hover {
    background-color: var(--secondary-color-hover);
}

.active {
    background-color: var(--primary-color);
    color: var(--text-color-light);
    font-weight: bold;
}

.active:hover {
    background-color: var(--primary-color-hover);
}