/* Card container */
.card {
    display: flex;
    flex-direction: column;
    height: 410px; /* fixed card height */
    width: 250px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background: linear-gradient(
        to bottom,
        #ffffff 0%,
        #ffffff 67%,
        #b2d8d8 100%
    );
    position: relative;
}



/* The main content container (image, body, tags) */
.content {
    width: 100%;
}

/* Unpublished content gets greyed out */
.unpublished {
    opacity: 0.5;
}

/* Image styling */
.image {
    width: 100%;
    height: 200px; /* fixed image height */
    object-fit: cover;
    cursor: pointer;
}

/* Body of the card */
.body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 8px;
    overflow: hidden;
}

/* Title container */
.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Title styling */
.title {
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* limit to 2 lines */
    -webkit-box-orient: vertical;
    cursor: pointer;
}

/* Share icon styling */
.share {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

/* User/date container */
.userDateContainer {
    margin-top: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
}

.username a {
    text-decoration: none;
    color: inherit;
}

/* Views and likes container */
.viewsLikes {
    margin-top: auto; /* push to bottom */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
}

/* Like and views styling */
.like,
.views {
    display: flex;
    align-items: center;
}

.likesImage,
.viewsImage {
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: var(--accent-color-light);
}

.likeCount {
    margin-left: 4px;
}

/* Options button container (outside the greyed content) */
.optionsButtonContainer {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 11;
}

/* Options button styling */
.optionsButton {
    background: transparent;
    border: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    font-size: 24px;   /* Ensures the ellipsis is visible */
    line-height: 24px; /* Aligns the content vertically */
    padding: 0;
    color: black;
}

/* (The options dropdown styling is already defined in ScenarioCardOptions) */

/* Tooltip container for unpublished scenario */
.tooltipContainer {
    position: relative;
}

/* Red exclamation icon */
.exclamationIcon {
    position: absolute;
    top: 8px;
    left: 8px;
    background: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-weight: bold;
    cursor: default;
}

/* Tooltip text is hidden by default */
.tooltipText {
    position: absolute;
    top: 30px;
    left: 8px;
    background: black;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    display: none;
}

/* Show tooltip when hovering over the exclamation icon or the tooltip text itself */
.exclamationIcon:hover + .tooltipText,
.tooltipText:hover {
    display: block;
}

/* Hover effect for title */
.hovered {
    text-decoration: underline;
}

/* Highlight text */
.highlight {
    background-color: yellow;
}
