.tabsContainer {
    display: flex;
    justify-content: space-around;
    height: 3.75rem; /* 60px */
}

.tab {
    flex: 1;
    text-align: center;
    padding: 0.625rem; /* 10px */
    cursor: pointer;
    font-weight: bold;
    border-bottom: 0.1875rem solid transparent; /* 3px */
    transition: background-color 0.3s ease, border-color 0.3s ease;
    color: var(--text-color-dark);

    /* Center text vertically */
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab:hover {
    background-color: var(--secondary-color);
}

.activeTab {
    border-color: var(--primary-color);
    color: var(--primary-color);
}
