/* CreateScenario.module.css */

/* Existing styles */
.container {
    display: flex;
    height: calc(100vh - var(--canvas-topbar-height));
    width: 100%;
    margin-top: var(--canvas-topbar-height);
}

.createSidebar {
    height: 100%;
    background-color: var(--background-color);
    border-right: 1px solid #ccc;
}

.canvas {
    height: 100%;
    background-color: #ffffff;
    position: relative;
    flex-grow: 1;
}

.editSidebar {
    height: 100%;
    background-color: var(--background-color);
    border-left: 1px solid #ccc;
    min-width: 400px;
}

.resizer {
    width: 5px;
    cursor: col-resize;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: relative;
}

.resizer:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .container {
        flex-direction: row;
    }

    /* Remove the CSS rule that hides the canvas */
    /* .canvas {
        display: none; 
    } */

    .editSidebar {
        width: 100%; /* The editSidebar takes full width on mobile */
        min-width: 0;
        border-left: none;
    }

    .resizer {
        display: none; /* Hide resizer on mobile */
    }
}
