.container {
    padding: 0px 10px;
}

.header {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.header h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
}

.iconItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin: 5px 0;
    background-color: #f5f5f5;
    color: #333;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s, box-shadow 0.3s;
    user-select: none;
    border: 1px solid transparent;
    font-size: 24px;
}

.iconItem:hover,
.iconItem:focus {
    background-color: #d0d0d0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-color: #bbb;
}

.nodeTypes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding-top: 20px;
}

.heatmapkey {
    width: 100%;
    height: 50px;
    background: linear-gradient(to right, white, blue, cyan, lightgreen, green, yellow, orange, red);
    border: 1px solid #ccc;
    margin: 20px 0;
}

.heatmaplabels {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.label {
    font-size: 14px;
    color: #333;
}
