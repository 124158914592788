.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modalContent {
    background-color: #fff;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    padding: 1.5rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 90vh;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.label {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 1rem;
    color: #333;
}

.input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.textarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    resize: vertical;
    min-height: 80px;
}

.scenariosSection {
    display: flex;
    flex-direction: column;
}

.sectionTitle {
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.scenarioList {
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 0.5rem;
    border-radius: 4px;
}

.scenarioItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 4px;
}

.scenarioItem:hover {
    background-color: #f0f0f0;
}

.selected {
    background-color: #d0ebff !important;
}

.scenarioImage {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
}

.assignSection {
    display: flex;
    flex-direction: column;
}

.checkboxLabel {
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.studentsList {
    margin-top: 0.5rem;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 0.5rem;
    border-radius: 4px;
}

.studentItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 4px;
}

.studentItem:hover {
    background-color: #f0f0f0;
}

.studentAvatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
}

.cancelButton {
    background-color: #ccc;
    color: #333;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.submitButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}
