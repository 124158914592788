/* ScenarioPassword.module.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height of the viewport */
    padding: 20px;
    box-sizing: border-box;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.h2 {
    margin-bottom: 20px;
}

.input {
    width: 250px;
    padding: 5px;
    padding-right: 30px;
    margin-right: 10px;
    font-family: var(--font);
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
    color: var(--text-color-dark);
    height: 38px;
}


.input::placeholder {
    color: var(--input-placeholder);
    font-family: var(--font);
}
  
.input:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 5px var(--primary-color);
}

.errorInput {
    border-color: red;
    box-shadow: 0 0 5px red;
}

.errorText {
    color: red;
    margin-bottom: 0px;
}

.button {
    margin-top: 30px;
    padding: 0.75rem 1.5rem;
    background-color: var(--primary-color); /* blue color */
    color: var(--text-color-light);
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* subtle shadow */
    transition: background-color var(--speed) ease;
}

.button:hover {
    background-color: var(--primary-color-hover); /* darker blue on hover */
}

