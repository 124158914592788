/* Edge.module.css */

.edgeLabel {
    font-size: 12px;
    fill: #000; /* Use 'fill' for SVG text */
    text-anchor: middle;
    alignment-baseline: middle;
    user-select: none;
}

.edgeLabelSelected {
    fill: #fff; /* Change color for selected label */
}

.edgeLabelGroup {
    transition: all 0.3s ease-in-out;
}
