.sidebarContainer {
    position: relative; /* ensure absolutely positioned elements go relative to this container */
    width: 300px;
    min-width: 300px;
    background-color: var(--primary-color);
    border-right: 1px solid #dfe3e8;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
}

/* The back icon link */
.backIcon {
    position: absolute;
    top: 16px;
    left: 16px;
    color: #fff; /* or var(--text-color-light) if you prefer */
    font-size: 1.4rem;
    text-decoration: none;
    z-index: 10;
    cursor: pointer;
}

/* Optionally add more space at the top so content doesn't overlap the icon:
   E.g., padding-top: 56px; 
   However, you already have 16px of padding which may suffice.
*/


/* Name displayed at the top */
.classroomName {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 4px;
    margin-top: 40px;
    color: var(--text-color-light);
}

/* Classroom owner */
.classroomOwner {
    font-size: 0.9rem;
    color: var(--text-color-light);
    margin-bottom: 12px;
}

/* Classroom image, slightly rounded rectangle */
.classroomImage {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 16px;
    border: 2px solid var(--text-color-light);
}

/* Navigation list (main items) */
.navList {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

/* Single nav item link */
.navItem {
    display: flex;
    align-items: center;
    color: var(--text-color-light);
    padding: 8px 12px;
    text-decoration: none;
    font-weight: 500;
    border-radius: 4px;
    margin-bottom: 4px;
    transition: background-color 0.2s ease-in-out;
}

.navItem:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Active main or sub item highlight */
.active {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Icon spacing inside main items */
.icon {
    margin-right: 8px;
}

/* Sub-nav list */
.subNavList {
    list-style: none;
    padding: 4px 0 4px 24px; /* Indent sub-items */
    margin: 4px 0;
}

/* Sub-nav item */
.subNavItem {
    display: flex;
    align-items: center;
    color: var(--text-color-light);
    text-decoration: none;
    font-size: 0.95rem;
    margin-bottom: 4px;
    transition: background-color 0.2s ease-in-out;
}

.subNavItem:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Active sub-item highlight */
.subNavItem.active {
    background-color: rgba(255, 255, 255, 0.3);
}