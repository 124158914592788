.overlay {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    z-index: 11;
}

.modal {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 40px;
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.inputField {
    width: 100%;
    font-size: 1rem;
    padding: 8px;
    margin: 10px 0;
}

.buttonRow {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 20px;
    width: 100%;
    margin-top: 20px;
    align-items: center;
}

.createButton {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.createButton:hover {
    background-color: var(--primary-color-hover);
}

.cancelButton {
    background-color: white;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.cancelButton:hover {
    background-color: var(--primary-color-light);
    color: white;
}

