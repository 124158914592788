/* TransferModal.module.css */
.overlay {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 11;
}

.modal {
    position: relative;
    background-color: white;
    padding: 40px 20px 20px 20px;
    border-radius: 40px;
    width: 500px;
    max-height: 80vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.exitModal {
    position: absolute;
    top: 20px;
    right: 23px;
    color: black;
    cursor: pointer;
    font-size: 26px;
    font-weight: bold;
}

.header {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
}

.searchContainer {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}

.searchContainer input {
    flex: 1;
    padding: 10px;
    border-radius: 20px 0 0 20px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.searchButton {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-left: none;
    background-color: #f0f0f0;
    border-radius: 0 20px 20px 0;
    cursor: pointer;
}

.searchResults {
    width: 100%;
}

.userCard {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 20px;
    transition: background-color 0.3s;
}

.userCard:hover {
    background-color: #f9f9f9;
}

.userCard.selected {
    background-color: #e0e0e0;
}

.profilePicture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.transferButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 20px;
}

.transferButton:hover {
    background-color: #0056b3;
}

.confirmContainer {
    text-align: center;
}

.confirmButtons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.yesButton,
.noButton {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    margin: 0 10px;
}

.yesButton {
    background-color: #28a745;
    color: white;
}

.noButton {
    background-color: #dc3545;
    color: white;
}
