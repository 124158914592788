/* Container and top controls */
.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
}

.searchBar {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    width: 100%;
}

.searchInput {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
    min-width: 300px;
    width: 40%;
}

.searchInput:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.sortContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.sortLabel {
    font-size: 16px;
    margin-right: 10px;
    color: #333;
}

.sortDropdown {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
    background-color: white;
    cursor: pointer;
}

.sortDropdown:hover,
.sortDropdown:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.categories {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.categoryTag {
    padding: 10px 15px;
    border-radius: 20px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: white;
    user-select: none;
}

.categoryTag.selectedCategory {
    border: 2px solid var(--primary-color-hover);
}

.addCategoryButton {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
    margin: 5px;
}

/* Grid container for scenarios */
.gridContainer {
    display: grid;
    gap: 16px;
    justify-content: center;
    /* Use auto-fit with a minmax of 330px (300px card + 30px extras) */
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

/* Each grid item centers its card */
.gridItem {
    display: flex;
    justify-content: center;
}

/* Overlay and loader */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 9999;
    pointer-events: none;
}

.overlay.active {
    pointer-events: auto;
}

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: var(--primary-color);
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .searchBar {
        margin-top: 20px;
        justify-content: center;
    }

    .sortContainer {
        justify-content: center;
    }

    .categories {
        justify-content: center;
    }
}
