.myClassroomsContainer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--navbar-height));
    background-color: var(--background-color);
    width: 100%;
    overflow: hidden;
}
  
.myClassroomsBody {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    margin-top: var(--my-classrooms-header-height);
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
