.tabsContainer {
    position: relative;
    margin-bottom: 16px;
    background-color: var(--background-color);
    padding: 0 10px;
}

.tabsBar {
    display: flex;
    align-items: center;
    z-index: 4;
    height: var(--tabsbar-height);
    overflow-x: auto;
    white-space: nowrap;
    user-select: none;
}

.tabsContent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.tabsBar[data-align-left="true"] .tabsContent {
    justify-content: flex-start;
}

/* Individual tabs */
.tab {
    color: black;
    font-size: 17px;
    border: 1.5px solid black;
    border-radius: 20px;
    padding: 5px 15px;
    margin-right: 7px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    white-space: nowrap; /* Prevent text from wrapping inside the tab */
}

.tab.active {
    background-color: var(--primary-color);
    border-color: white;
    color: var(--text-color-light);
}

/* Caret icon next to the Education tab */
.caretIcon {
    margin-left: 5px;
    font-size: 14px;
    pointer-events: none;
}

.activeTab {
    color: var(--primary-color);
    border-bottom: 3px solid var(--primary-color);
}

/* Mobile optimizations */
@media (max-width: 768px) {
    .tabsBar {
        margin-top: 20px;
        white-space: normal; /* Allow the container to break lines */
        overflow-x: visible; /* Avoid horizontal scrolling */
        margin-bottom: 35px;
    }

    .tabsContent {
        flex-wrap: wrap; /* Enable tab bubbles to wrap to the next line */
        justify-content: center;
    }

    .tab {
        font-size: 15px; /* Slightly smaller text */
        padding: 8px 12px; /* Adjust padding for better tap targets */
        margin-right: 5px;
        margin-bottom: 5px; /* Extra spacing when wrapping */
    }
}
