/* Node Container */
.nodeContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 250px; /* Maximum width to allow growth */
    min-width: 120px; /* Minimum width */
    margin: auto;
    text-align: center;
    cursor: pointer;
}

/* When the node is being dragged */
.nodeContainer:active {
    cursor: grabbing; /* Change to grabbing cursor when dragging */
}

/* Node Content */
.nodeContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 50px; /* Minimum height to ensure node size even without text */
    min-width: 120px; /* Ensure the minimum width to maintain structure */
    padding: 10px; /* Add padding to give space around text */
    border: 1px solid var(--border-color, #ddd);
    background-color: var(--fill-color, #fff);
    transition: background-color 0.3s, border-color 0.3s;
    word-break: break-word; /* Break long words */
    white-space: normal; /* Allow text to wrap */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}


/* Set maximum height for the content */
.nodeContainer .nodeContent > * {
    max-height: 40px; /* Max height for two lines */
}

/* Selected Node with Glow Effect */
.nodeContainer.selected .nodeContent {
    border-color: var(--primary-color);
    border-width: 2px;
    box-shadow: 0 0 15px 5px rgba(0, 153, 204, 0.6); /* Glowing teal shadow */
    animation: glow 1.5s infinite; /* Pulsating animation */
}

/* Pulsating Keyframe Animation */
@keyframes glow {
    0% {
        box-shadow: 0 0 15px 5px rgba(0, 153, 204, 0.6);
    }
    50% {
        box-shadow: 0 0 20px 10px rgba(0, 153, 204, 0.8);
    }
    100% {
        box-shadow: 0 0 15px 5px rgba(0, 153, 204, 0.6);
    }
}


/* Regular Node */
.nodeContainer.regularNode .nodeContent {
    border-radius: 10px;
}

/* Start Node */
.nodeContainer.startNode .nodeContent {
    border-radius: 25px; /* Fully rounded corners */
    border-style: double;
    border-width: 3px;
}

/* End Node */
.nodeContainer.endNode .nodeContent {
    border-radius: 0; /* Square corners */
    border-style: dashed;
    border-width: 2px;
}

/* Node Label */
.nodeLabel {
    position: absolute;
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    pointer-events: none;
}

/* START Label */
.startNode .nodeLabel {
    color: var(--start-node-label-color, green);
}

/* END Label */
.endNode .nodeLabel {
    color: var(--end-node-label-color, red);
}

/* Handles */
.handle {
    transition: all 0s ease !important;
    z-index: 10 !important;
}

.handleTarget,
.handleSource {
    width: 12px !important;
    height: 12px !important;
    border-radius: 6px !important;
    transform: translateY(-5px) !important;
}

.handleTarget {
    background: var(--accent-color) !important;
    color: var(--accent-color) !important;
    transform: translateX(-2px) translateY(-5px) !important;
}

.handleTarget:hover {
    background: var(--accent-color-hover) !important;
    color: var(--accent-color-hover) !important;
}

.handleSource {
    background: var(--primary-color) !important;
    color: var(--primary-color) !important;
    transform: translateX(2px) translateY(-5px) !important;
}

.handleSource:hover {
    background: var(--primary-color-hover) !important;
    color: var(--primary-color-hover) !important;
}


/* Edge Connecting Node with Animated Dotted Border */
.nodeContainer.edgeConnecting .nodeContent {
    border-color: var(--primary-color);
    border-style: dotted;
    border-width: 2px;
    box-shadow: 0 0 15px 5px rgba(0, 153, 204, 0.6); /* Similar glow effect */
    animation: borderAnimation 1.5s infinite; /* Pulsating animation */
}

@keyframes borderAnimation {
    0% {
        border-color: var(--primary-color);
    }
    50% {
        border-color: transparent;
    }
    100% {
        border-color: var(--primary-color);
    }
}
