.choicesContainer {
    display: flex;
    padding-top: 30px;
    flex-direction: column;
    gap: 10px;
}

.choiceItem {
    user-select: none;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease;
}

.choiceItem:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.choiceTitle {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

.nextSituationTitle {
    font-size: 14px;
    color: #777;
}
