.footer {
    background-color: #006666;
    height: 200px;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    gap: 150px;
    padding: 50px 150px 50px 120px;
}

.footerTitle {
    font: 30px;
    color: white;
    margin-bottom: 10px;
}

.footerInfo {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: white;
    line-height: 1.5;
}


.footerInfo2 {
    margin-left: 30px;
}


.siteMap {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logoImage {
    width: 200px;
    height: 80px;
    object-fit: contain;
}


.link {
    color: white;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    line-height: 1.5;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    font-family: inherit;
    margin-bottom: 5px;
}

.link:hover {
    text-decoration: underline;
    color: var(--secondary-color);
}
