.container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    align-items: center;
    margin-bottom: 10vh;
    margin-top: 10vh;
    width: 80%;
}

.container h3 {
    margin-bottom: 50px;
}

.browseResults {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    /* Adjust minmax as needed */
    grid-gap: 1em;
    width: 100%;
}
