.container {
    display: flex;
    flex-direction: column;
    padding: 10px 40px;
    width: 100%;
    background-color: transparent;
}

.logo {
    height: 52px;
    align-self: center;
    margin-bottom: 10px;
}
  
.h1 {
    margin-bottom: 20px;
    font-size: 32px;
    text-align: center;
    color: #333;
    font-family: var(--font);
    font-weight: bold;
}
  
.form {
    display: flex;
    flex-direction: column;
}

  
.input {
    margin-top: 20px;
}



.forgotPassword {
    margin-top: 10px;
    align-self: flex-end;
}

.showPassword {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
    color: var(--text-color-dark);
    font-family: var(--font);
}

.checkbox {
    margin-right: 8px;
    margin-bottom: 3px;
}
  
  
.button {
    margin-top: 30px;
    padding: 10px;
    font-size: 20px;
    font-family: var(--font);
    font-weight: bold;
    color: white;
    background-color: var(--accent-color);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    height: 60px;
    margin-bottom: 10px;
    user-select: none;
}
  
.button:hover {
    background-color: var(--accent-color-hover);
}
  
.error {
    margin-top: 10px;
    color: red;
    font-size: 14px;
    text-align: start;
}

.success {
    font-size: 16px;
    color: green;
    text-align: center;
    margin-top: 20px;
}

  
.links {
    margin-top: 10px;
    text-align: center;
    color: var(--text-color-dark);
    font-size: 15px;
    font-family: var(--font);
}

.linkSpan {
    color: var(--primary-color);
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    font-family: var(--font);
}

.label {
    color: var(--text-color-dark);
    font-weight: bold;
    font-size: 15px;
    font-family: var(--font);
}

.forgotPasswordButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}


.forgotPasswordButton {
    margin-top: 30px;
    padding: 10px;
    font-size: 15px;
    font-family: var(--font);
    font-weight: bold;
    color: white;
    background-color: var(--accent-color);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    height: 40px;
    margin-right: 10px;
    user-select: none;
}

.forgotPasswordButton:hover {
    background-color: #dd5d3a;
}

.container p {
    text-align: start;
    color: var(--text-color-dark);
    font-size: 15px;
    font-family: var(--font);
}
  