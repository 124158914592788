.dashboardContainer {
    display: flex;
    flex-direction: column;
    gap: 16px; /* Vertical space between the sections */
    padding: 30px;
    overflow-y: auto;
}

/* Each rectangular section */
.sectionBox {
    background-color: var(--secondary-color);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column; /* Arrange announcement box and button horizontally */
    align-items: center;
    justify-content: space-between; /* Distribute announcement box and button */
    gap: 16px; /* Add some spacing between elements */
}

/* Section Title Link styling */
.sectionTitleLink {
    text-decoration: none;
    color: var(--text-color);
    display: inline-block;
    margin-bottom: 8px;
}

.sectionTitleLink:hover h2 {
    text-decoration: underline; /* underline the title on hover, if desired */
}

/* The box that wraps an individual announcement */
.announcementBox {
    background-color: var(--background-color);
    border-radius: 4px;
    padding: 12px;
    min-height: 100px;
    flex-grow: 1; /* Allow the announcement box to take available space */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    color: var(--text-color);
    width: 100%;
}

/* The header for the last announcement */
.announcementHeader {
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 0.95rem;
    color: var(--text-color);
}

/* The poster's username styling */
.posterUsername {
    color: var(--text-color);
    margin-left: 4px;
}

/* The actual announcement content below the header */
.announcementContent {
    background-color: var(--background-color);
    padding: 4px 0;
    font-size: 0.95rem;
    line-height: 1.4;
}

/* View All Announcements link */
.viewAllLink {
    background-color: var(--primary-color);
    color: var(--text-color-light);
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 10px 16px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    flex-shrink: 0; /* Prevent the button from shrinking */
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewAllLink:hover {
    background-color: var(--primary-color-hover); /* Adjust for hover effect */
}

.scenarioItem {
    padding: 8px;
    transition: background-color 0.2s ease;
}
.scenarioItem:hover {
    background-color: #f0f0f0; /* Example hover effect */
}


.foldersBody {
    padding-top: 2em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 20px;
    width: 100%;
    padding: 30px 80px;
    overflow-y: auto;
}

.folderItem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.folderItemImage {
    width: 64px;
    height: 64px;
    color: var(--primary-color);
    cursor: pointer;
}

.folderItem span {
    margin-top: 8px;
}

