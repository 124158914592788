/* Existing styles */

.announcementsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 80px;
    overflow-y: auto;
}

.createAnnouncementContainer,
.announcementsGrid {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.announcement {
    margin-top: 30px;
    width: 100%;
    max-width: 100%;
    position: relative;
    padding: 15px;
    background-color: var(--background-color);
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    word-wrap: break-word;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
}

.announcement img,
.announcement iframe,
.announcement video {
    max-width: 100%;
    height: auto;
}

.announcement p {
    word-wrap: break-word;
    overflow-wrap: break-word;
}


.optionsButton {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 9;
    font-size: 18px;
    color: #333;
}


.optionsDropdown {
    position: absolute;
    top: 45px;
    right: 15px;
    z-index: 14;
    background-color: var(--background-color);
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    min-width: 100px;
}

.optionsDropdownItem {
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
}

.optionsDropdownItem:hover {
    background-color: #f5f5f5;
}

.ck-editor__top {
    @apply sticky top-0 z-10 !important;
  }
  
  .ck-sticky-panel__content {
    @apply static !important;
  }

