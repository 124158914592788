.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 40px;
}

.imageContainer {
    margin-bottom: 1.5rem;
    width: 100%;
    max-width: 30rem;
    margin-bottom: 40px;
}

.image {
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.text {
    width: 100%;
    box-sizing: border-box;
    overflow-wrap: break-word;
}

.text img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.text iframe {
    max-width: 100%;
    display: block;
    margin: 20px auto;
}

.text figure {
    display: flex;
    justify-content: center;
}

.text ul,
.text ol {
    margin: 1em 0;
    padding-left: 40px;
}

.text li {
    margin: 0.5em 0;
    list-style-type: disc;
}

/* New styles */
.mediaContainer {
    margin-bottom: 20px;
    width: 100%;
    max-width: 30rem;
}

.media {
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
