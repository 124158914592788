.tabsContainer {
    display: flex;
    align-items: center;
    height: var(--classroom-tabs-height);
    min-height: var(--classroom-tabs-height);
    border-bottom: 1px solid #e0e0e0;
    background-color: var(--background-color);
}

.tab {
    padding: 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color-dark);
    text-decoration: none;
    font-weight: 500;
    position: relative;
    transition: background-color 0.3s ease;
}

.tab:hover {
    background-color: var(--light-hover-color);
}

.tab.active {
    color: var(--primary-color);
    font-weight: 600;
}

.tab.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 3px;
    background-color: var(--primary-color);
}
