.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    height: 100%;
    width: 100%;
    flex-grow: 1;
    padding-bottom: 40px;
    padding-top: 40px;
}

.imageContainer {
    margin-bottom: 1.5rem;
    width: 100%;
    max-width: 20rem;
}

.image {
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-color-dark);
    margin-bottom: 1rem;
    text-align: center;
}

.description {
    max-width: 32rem;
    text-align: center;
    margin-bottom: 1.5rem;
}

.button {
    padding: 0.75rem 1.5rem;
    background-color: var(--primary-color);
    color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color var(--speed) ease;
}

.button:hover {
    background-color: var(--primary-color-hover);
}

.comments {
    margin-top: 30px;
}

.viewCommentsLink {
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
}
