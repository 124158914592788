.settingsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--background-color);
    padding-top: 30px;
}

.settingItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
}

.settingItem label {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
}

.switch {
    margin-left: 16px;
}

.expandedSettingItem {
    margin-top: 16px;
    padding: 12px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
}

.hintNote {
    font-size: 12px;
    color: #666;
    margin-bottom: 10px;
    line-height: 1.4;
}

/* Existing .settingsContainer stays unchanged */

/* Style for the label and color picker */
.colorPickerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensures the label and input have space between */
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
}

.colorPickerContainer label {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-right: 10px; /* Space between label and color picker */
}

.colorPicker {
    width: 50px;
    height: 30px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: box-shadow 0.3s;
}

.colorPicker:hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
}

/* Style for the delete button */
.deleteButton {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: var(--accent-color);
    color: white;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 16px; /* Spacing from color picker */
}


/* For responsiveness and consistent spacing */
@media (max-width: 768px) {
    .colorPickerContainer {
        flex-direction: column;
        align-items: flex-start;
    }

    .colorPicker {
        margin-top: 10px;
    }

    .deleteButton {
        margin-top: 24px;
    }
}


