.container {
    /* Ensures the page takes full height and a column layout */
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    margin-top: 0;
    background-color: var(--background-color);
}

/* Hero Section */
.heroSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* A slightly more dynamic teal gradient */
    background: linear-gradient(
        180deg,
        #b2d8d8 0%,
        #bce4e3 50%,
        #86c8c8 100%
    );
    color: #333;
    padding: 100px 20px;
}

.heroSection h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.heroSection p {
    font-size: 1.2rem;
    max-width: 700px;
    margin-bottom: 30px;
}

.heroButton {
    background-color: #fff;
    color: #333;
    padding: 15px 30px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.heroButton:hover {
    background-color: #f5f5f5;
}

/* About Section */
.aboutSection {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: #ffffff; /* stays plain white */
    padding: 80px 20px;
}

.aboutImage {
    width: 300px;
    max-width: 100%;
    margin: 20px;
}

.aboutText {
    flex: 1;
    max-width: 600px;
    min-width: 300px;
    margin: 20px;
    text-align: left;
}

.aboutText h2 {
    margin-bottom: 20px;
}

.aboutText p {
    line-height: 1.6;
}

/* Features Sections */

/* Creators */
.featuresSectionCreators {
    /* Use an accent color gradient with multiple stops for visual interest */
    background: linear-gradient(
        180deg,
        #d66d50 0%,
        #ea8164 40%,
        #e38969 100%
    );
    color: #ffffff;
    text-align: center;
    padding: 80px 20px;
}

/* Viewers */
.featuresSectionViewers {
    /* Another accent color gradient for viewers */
    background: linear-gradient(
        180deg,
        #b2d8d8 0%,
        #bce4e3 50%,
        #86c8c8 100%
    );
    color: #333;
    text-align: center;
    padding: 80px 20px;
}

.sectionTitle {
    font-size: 2.5rem;
    margin-bottom: 40px;
}

.featuresGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
}

.featureItem {
    max-width: 300px;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.icon {
    font-size: 2rem;
    margin-bottom: 15px;
}

/* Industry Section */
.industrySection {
    text-align: center;
    padding: 80px 20px;
    background-color: #fefefe;
}

.sectionSubtitle {
    font-size: 1.1rem;
    max-width: 700px;
    margin: 0 auto 40px;
    line-height: 1.6;
    color: #555;
}

.industryGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
}

.industryCard {
    max-width: 300px;
    background-color: #fff;
    border-radius: 8px;
    padding: 30px 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    text-align: center;

    /* Center content similar to Features */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.industryIcon {
    font-size: 2rem;
    color: #d66d50; /* accent color */
    margin-bottom: 15px;

    /* Ensure consistent icon height */
    height: 2.5rem;
    line-height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.industryCard h3 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #333;

    /* Ensure alignment with icon */
    display: flex;
    align-items: center;
    justify-content: center;
}

.industryCard p {
    font-size: 1rem;
    line-height: 1.5;
    color: #666;
}


/* CTA Section */
.ctaSection {
    background-color: #ffffff; /* stays plain white */
    text-align: center;
    padding: 80px 20px;
}

.ctaSection h2 {
    margin-bottom: 20px;
}

.ctaSection p {
    max-width: 600px;
    margin: 0 auto 30px auto;
    line-height: 1.6;
}

.ctaButton {
    background-color: #d66d50;
    color: #ffffff;
    padding: 15px 30px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: opacity 0.3s ease;
}

.ctaButton:hover {
    opacity: 0.9;
}
