.subscriptionContainer {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
}

.errorAlert {
    background-color: var(--alert-color);
    color: white;
    padding: 1rem;
    border-radius: 6px;
    margin-bottom: 1rem;
}

.successAlert {
    background-color: var(--primary-color);
    color: white;
    padding: 1rem;
    border-radius: 6px;
    margin-bottom: 1rem;
}

.subscriptionCard {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
}

.planDetails {
    flex-grow: 1;
}

.planDetails h3 {
    color: var(--primary-color);
    margin-bottom: 0.5rem;
}

.cancelButton {
    background-color: var(--accent-color);
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    flex-shrink: 0;
}

.cancelButton:hover:not(:disabled) {
    background-color: var(--accent-color-hover);
}

.cancelButton:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.paymentHistory {
    margin-top: 2rem;
}

.paymentItem {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: var(--light-hover-color);
    margin: 0.5rem 0;
    border-radius: 6px;
}

@media (max-width: 768px) {
    .subscriptionCard {
        flex-direction: column;
        align-items: stretch;
    }
    
    .cancelButton {
        width: 100%;
        margin-top: 1rem;
    }
}