/* CreateSidebar.module.css */

.container {
    display: flex;
    height: 100%;
    position: relative;
}

.iconSidebar {
    width: var(--canvas-topbar-height); /* Fixed width of 60px */
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    box-sizing: border-box;
    border-right: 1px solid #ccc;
}

.iconItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin: 5px 0;
    background-color: #f5f5f5;
    color: #333;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s, box-shadow 0.3s;
    user-select: none;
    border: 1px solid transparent;
    font-size: 24px;
}

.iconItem:hover,
.iconItem:focus {
    background-color: #d0d0d0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-color: #bbb;
}

.activeIcon {
    background-color: var(--primary-color);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
    border-color: var(--secondary-color);
    color: #fff;
}

.activeIcon:hover,
.activeIcon:focus {
    background-color: var(--primary-color);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.5);
    border-color: var(--secondary-color);
    color: #fff;
}

.disabledIcon {
    pointer-events: none; /* Prevent click interactions */
    opacity: 0.5; /* Make it look visually disabled */
    cursor: not-allowed;
}


/* Expanded Panel */
.expandedPanel {
    width: 0; /* Initial width when not visible */
    background-color: var(--background-color);
    overflow-y: auto;
    position: absolute; /* Position it absolutely */
    top: 0;
    left: var(--canvas-topbar-height); /* Position it right next to the icon sidebar */
    height: 100%;
    z-index: 10; /* Ensure it's above other content */
    border-right: 1px solid #ccc;
    transition: width 0.3s ease; /* Transition for smooth sidebar opening */
    padding-bottom: 20px;
}

/* When the panel is active (sidebar expanded) */
.activePanel {
    width: 250px; /* Final width when expanded */
}

.tocContainer {
    position: fixed;
    background-color: var(--background-color);
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    /* Adjust expandedPanel for mobile devices */
    .expandedPanel {
        position: fixed; /* Ensure it overlays properly */
        top: var(--canvas-topbar-height);
        left: var(--canvas-topbar-height); /* Keep the same left position */
        height: 100%;
        width: 0;
        transition: width 0.3s ease;
        border-right: none;
        z-index: 1000; /* Ensure it's above other elements */
    }

    .activePanel {
        width: calc(100% - var(--canvas-topbar-height)); /* Take up the remaining width */
    }
}
