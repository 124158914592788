.autoscrollButtonBottom,
.autoscrollButtonTop {
  position: fixed;
  background-color: rgb(0, 102, 102);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform-origin: center;
}

.autoscrollButtonBottom:hover,
.autoscrollButtonTop:hover {
  background-color: #006666;
  transform: scale(1.1);
}

.autoscrollButtonBottom {
  bottom: 20px;
  left: 50%;
}

.autoscrollButtonTop {
  top: 140px;
  left: 50%;
}
