.customContainer {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.OtherScenariosRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: auto;
  margin: auto;
  padding-bottom: 2rem;
}

.OtherScenariosRow>* {
  margin: 7.5px 7.5px;
}
