.foldersContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
}

.foldersHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding-left: 20px;
}

.title {
    margin: 0;
    margin-right: 12em;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
}

.addFolderIcon,
.addScenarioIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--background-color);
    color: black;
    border: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
    transition: background-color 0.3s ease;
    margin-right: 1em;
}

.addFolderIcon:hover,
.addScenarioIcon:hover {
    background-color: #dedede;
}

.addFolderIcon::after {
    content: "Add a Folder";
}

.addScenarioIcon::after {
    content: "Add a Scenario";
}

.createFolderButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--background-color);
    color: black;
    border: none;
    padding: 0;
    padding-bottom: 8px;
    font-size: 40px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    margin-right: 1em;
}

.createFolderButton:hover {
    background-color: #dedede;
}

.createFolderButton::after {
    content: "Create a Folder";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
    white-space: nowrap;
    margin-bottom: 10px;
}

.createFolderButton:hover::after {
    opacity: 1;
    transition-delay: 0s;
}

.foldersBody {
    padding-top: 2em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 20px;
    width: 100%;
    padding: 30px 80px;
    overflow-y: auto;
}

.folderItem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.folderItemImage {
    width: 64px;
    height: 64px;
    color: var(--primary-color);
    cursor: pointer;
}

.folderItem span {
    margin-top: 8px;
}

.optionsButton {
    position: absolute;
    top: 5px;
    right: -5px;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    outline: none;
}

.optionsButton:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.deleteButton {
    background-color: transparent;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
    color: red;
}

.deleteButton:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.deleteButton:active {
    background-color: rgba(0, 0, 0, 0.1);
}

.listHeader {
    margin-top: 20px;
}
