.gridContainer {
    display: grid;
    gap: 16px;
    margin: 0 auto;
    justify-content: center;       /* Centers the grid horizontally */
    align-content: center;         /* Centers rows vertically if extra space exists */
    grid-template-columns: repeat(5, 1fr);
}

@media (max-width: 1400px) {
    .gridContainer {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 1100px) {
    .gridContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 800px) {
    .gridContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 500px) {
    .gridContainer {
        grid-template-columns: 1fr;
    }
}
