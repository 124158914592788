.title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
}

.userandlike {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.likesButton {
    padding: 0px 8px;
    border-radius: 5px;
    font-size: 10px;
}

.likesImage {
    width: 25px;
    height: 25px;
}

.likesImage:hover {
    cursor: pointer;
}

.deleteButton {
    width: 20px;
    height: 20px;
    right: 10px;
    top: 7px;
    position: absolute;
}

.deleteButton:hover {
    cursor: pointer;
}

.messageDeleteModule {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.likesModule {
    display: flex;
}

.likes {
    margin-left: 5px;
    font-size: 15px;
}

.timestamp {
    font-size: 10px;
    color: gray;
    align-self: flex-end;
    margin-right: 7px;

}

.comment {
    text-align: left;
    border-color: var(--primary-color);
    border-width: 1.5px;
    border-radius: 5px;
    padding-left: 10px;
    padding-bottom: 7px;
    padding-top: 5px;
    margin: 10px 0px;
    width: 400px;
    position: relative;
}

.username {
    font-size: 15px;
    color: gray;
}

.commentForm {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.submitButton {
    border-radius: 10px;
    margin-left: 10px;
}

.textarea {
    border: 1.5px solid;
    width: 280px;
    border-color: var(--primary-color);
    border-radius: 5px;
    padding: 3px 5px;
    font-size: 15px;
    color: var(--primary-color);
    resize: none;
}

.textarea:focus {
    outline: none;
}

.message {
    text-wrap: wrap;
    width: 350px;
}

