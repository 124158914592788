/* HintModal.module.css */

.modalBackdrop {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 80%;
    text-align: flex-start;
    max-height: 500px;
    overflow-y: auto;
    position: relative;
}

.text {
    text-align: flex-start;
    width: 100%;
    box-sizing: border-box;
    overflow-wrap: break-word;
}

.text img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    align-self: center;
}

.text ul,
.text ol {
    margin: 1em 0;
    padding-left: 40px;
}

.text li {
    margin: 0.5em 0;
    list-style-type: disc;
}

.exitModal {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    cursor: pointer;
    font-size: 26px;
    font-weight: bold;
}
