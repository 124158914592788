.overlay {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    z-index: 16;
}

.modal {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 40px;
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 17;
}

.exitModal {
    position: absolute;
    top: 20px;
    right: 23px;
    color: black;
    cursor: pointer;
    font-size: 26px;
    font-weight: bold;
    z-index: 17;
}
