.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.formWrapper {
    width: 500px;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: var(--secondary-color);
}


.form {
    display: flex;
    flex-direction: column;
}

.label {
    font-size: 16px;
    color: var(--text-color-dark);
    font-family: var(--font);
}

.input {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
}

.input:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 5px var(--primary-color);
}

.button {
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: var(--accent-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-end;
    font-family: var(--font);
}

.button:hover {
    background-color: var(--accent-color-hover);
}

.error {
    margin-top: 10px;
    color: red;
    font-size: 14px;
    text-align: center;
}

.success {
    font-size: 16px;
    color: green;
    text-align: center;
    margin-top: 20px;
}

.linkSpan {
    color: var(--text-color-dark);
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    font-family: var(--font);
}
