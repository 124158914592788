/* UserCard.module.css */

.userCard {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    transition: box-shadow 0.3s ease-in-out;
}

.userCard:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.imageContainer {
    flex-shrink: 0;
    margin-right: 10px;
}

.profileImage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.textContainer h5 {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.usernameLink {
    text-decoration: none;
    color: #333;
}

.usernameLink:hover {
    text-decoration: underline;
    color: #007bff;
}
