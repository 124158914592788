/* CreateKey.module.css */

.createKeyContainer {
    padding: 0.5rem;
    background-color: var(--background-color);
    color: var(--text-color-dark);
    font-family: var(--font);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.createButton {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: var(--primary-color);
    color: var(--text-color-light);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color var(--speed) ease;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    width: 100%;
    max-width: 200px;
    margin-top: 0.6rem;
    margin-bottom: 2.5rem;
}

.createButton:hover {
    background-color: var(--primary-color-hover);
}

.keysList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.keyItem {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    padding: 0.5rem;
    background-color: #f9f9f9;
    transition: background-color var(--speed) ease;
    cursor: pointer;
}

.keyItem.expanded {
    background-color: #e9f5f5;
    cursor: default;
}

.keyHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.keyHeader h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    user-select: none;
}

.keyActions {
    display: flex;
    align-items: center;
}

.icon {
    cursor: pointer;
    margin-left: 5px;
    color: var(--primary-color);
    transition: color var(--speed) ease;
    font-size: 1rem;
}

.icon:hover {
    color: var(--primary-color-hover);
}

.description {
    font-size: 0.85rem;
    color: var(--text-color-dark);
    margin-top: 0.25rem;
    line-height: 1.2;
}

.collapsedDescription {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    user-select: none;
}

.expandedDescription {
    display: block;
}

.keyContent {
    margin-top: 0.5rem;
}

.keyActionsMiddle {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    gap: 0.5rem;
}

.keyActionsMiddle button {
    padding: 0.25rem 0.5rem;
    background-color: var(--secondary-color);
    color: var(--text-color-dark);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color var(--speed) ease;
    font-size: 0.8rem;
}

.keyActionsMiddle button:hover {
    background-color: var(--secondary-color-hover);
}

.keyForm {
    background-color: #fff;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
}

.formGroup {
    margin-bottom: 0.5rem;
}

.formGroup label {
    display: block;
    margin-bottom: 0.25rem;
    font-weight: bold;
    font-size: 0.9rem;
}

.formGroup input,
.formGroup textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    font-family: var(--font);
    color: var(--text-color-dark);
}

.formGroup textarea {
    resize: vertical;
    min-height: 80px;
}

.formGroup input:focus,
.formGroup textarea:focus {
    border-color: var(--primary-color);
    outline: none;
    box-shadow: 0 0 3px rgba(0, 102, 102, 0.5);
}

.formActions {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.formActions button {
    padding: 0.25rem 0.5rem;
    background-color: var(--secondary-color);
    color: var(--text-color-dark);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color var(--speed) ease;
    font-size: 0.8rem;
}

.formActions button:hover {
    background-color: var(--secondary-color-hover);
}

.error {
    color: var(--alert-color);
    font-size: 0.75em;
    margin-top: 0.25rem;
}

.required {
    color: var(--alert-color);
}

.keySections {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.section {
    background-color: #f9f9f9;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
}

.section h4 {
    margin-bottom: 0.25rem;
    color: var(--primary-color);
    font-size: 0.9rem;
}

.section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.section ul li {
    margin-bottom: 0.25rem;
    padding-left: 0.75rem;
    position: relative;
    font-size: 0.85rem;
}

.section ul li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: var(--primary-color);
}

.section p {
    margin: 0;
    color: var(--text-color-dark);
    font-size: 0.85rem;
}
