/* ViewTableOfContents.module.css */

.container {
    padding: 0px 20px;
    padding-top: 40px;
    overflow-y: auto;
    border-right: 1px solid #ccc;
    background-color: #ffffff;
    position: fixed;
    top: var(--navbar-height);
    left: 0px;
    height: calc(100% - var(--navbar-height));
    width: var(--toc-width);
    min-width: 240px;
    z-index: 2;
}

@media (max-width: 820px) {
    .container {
        width: 100%;
    }
}

.header {
    position: fixed;
    top: var(--navbar-height);
    left: 20px;
    width: var(--toc-width);
    background-color: var(--background-color);
    border: none;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.tocTitle {
    font-size: 20px;
    transform: translateY(-4px); /* Shifts the title up by 4 pixels */
}

.backButton {
    cursor: pointer;
    font-size: 24px;
    color: var(--primary-color);
}

.backButton:hover {
    color: var(--primary-color-hover);
}

.tocContents {
    margin-top: 60px; /* Adjusted to account for the fixed header */
}

.tocContent {
    margin-bottom: 20px;
}

.tocSection {
    margin-bottom: 20px;
}

.tocSectionLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    padding: 5px 0;
}

.chevronIcon {
    font-size: 14px;
    transition: transform 0.3s ease;
}

.tocItemsList {
    padding-top: 10px;
}

.tocItem {
    padding: 5px 25px;
    cursor: pointer;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    border-radius: 5px;
}

.tocItem:hover {
    background-color: var(--light-hover-color);
}

.selectedItem {
    background-color: var(--secondary-color);
}

.selectedItem:hover {
    background-color: var(--secondary-color-hover);
}

/* Mobile styles */
@media (max-width: 820px) {
    .header {
        left: 0;
        width: 100%;
        justify-content: center;
        padding: 10px 20px;
        box-sizing: border-box;
    }

    .backButton {
        position: absolute;
        left: 20px;
    }
}
