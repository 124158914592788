.linkSituationContainer {
    display: flex;
    flex-direction: column;
    height: calc(100% - 58px);
    padding: 1.25rem;
    background-color: var(--background-color);
}

.selectedChoiceTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--text-color-dark);
}

.situationListTitle {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: var(--text-color-dark);
}

.searchInput {
    width: 100%;
    padding: 0.625rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    font-size: 1rem;
    color: var(--text-color-dark);
    flex-shrink: 0;
}

.situationList {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    flex-grow: 1;
}

.situationItem {
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    background-color: var(--background-color);
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    color: var(--text-color-dark);
}

.situationItem:hover {
    background-color: var(--secondary-color);
}

.highlightedSituation {
    background-color: var(--primary-color);
    color: var(--text-color-light);
}

.situationItem:hover:not(.highlightedSituation) {
    background-color: var(--secondary-color-hover);
}
