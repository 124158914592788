/* General Styles */
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  padding: 0 20px;
  font-family: var(--font);
  background-color: var(--background-color);
  color: var(--text-color-dark);
}

.contentWrapper {
  width: 100%;
  max-width: 800px;
  padding: 20px 0;
}

/* Header */
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: var(--text-color-dark);
}

.backButton {
  align-self: flex-start;
  padding: 8px 16px;
  margin-bottom: 20px;
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 16px;
}

.backButton:hover {
  text-decoration: underline;
}

/* Controls */
.controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.controls button {
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: var(--primary-color);
  color: var(--text-color-light);
  border-radius: 5px;
  transition: background-color 0.2s;
}

.controls button:hover {
  background-color: var(--primary-color-hover);
}

/* Section */
.sections {
  width: 100%;
}

.section {
  margin-bottom: 24px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: var(--secondary-color);
  border-radius: 8px 8px 0 0;
}

.sectionHeader span {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-color-dark);
  cursor: pointer;
}

.sectionHeader:hover {
  background-color: var(--secondary-color-hover);
}

.sectionEdit {
  display: flex;
  align-items: center;
  width: 100%;
}

.sectionEdit input {
  flex-grow: 1;
  font-size: 18px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.sectionEdit input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(0, 102, 102, 0.5);
}

.sectionEdit button {
  margin-left: 8px;
  padding: 8px 16px;
  font-size: 14px;
  background-color: var(--primary-color);
  color: var(--text-color-light);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sectionEdit button:hover {
  background-color: var(--primary-color-hover);
}

.sectionActions {
  display: flex;
  align-items: center;
}

.sectionActions button {
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 14px;
  margin-left: 16px;
}

.sectionActions .deleteButton {
  color: var(--accent-color);
}

.sectionActions button:hover {
  text-decoration: underline;
}

/* Section Content */
.sectionContent {
  padding: 0;
  background-color: var(--background-color);
  border: 1px solid #eaecef;
  border-top: none;
  border-radius: 0 0 8px 8px;
}

/* Item List */
.itemList {
  padding: 0;
  margin: 0;
}

.itemContainer {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #eaecef;
  background-color: #fff;
}

.itemContainer:last-child {
  border-bottom: none;
}

.itemContainer:hover {
  background-color: var(--light-hover-color);
}

.item {
  flex-grow: 1;
  font-size: 16px;
  color: var(--text-color-dark);
  cursor: pointer;
}

.itemContainer button {
  background: none;
  border: none;
  color: var(--accent-color);
  cursor: pointer;
  font-size: 14px;
}

.itemContainer button:hover {
  text-decoration: underline;
}

/* Section Input */
.sectionInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 8px;
}

.sectionInput input {
  flex: 1;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  min-width: 0;
}

.sectionInput input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(0, 102, 102, 0.5);
}

.buttonContainer {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.sectionInput button {
  padding: 12px 16px;
  font-size: 16px;
  background-color: var(--primary-color);
  color: var(--text-color-light);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sectionInput button:last-child {
  background-color: var(--accent-color);
}

.sectionInput button:hover {
  background-color: var(--primary-color-hover);
}

.sectionInput button:last-child:hover {
  background-color: var(--accent-color-hover);
}

/* Responsive Design */
@media (max-width: 768px) {
  .title {
      font-size: 24px;
  }

  .backButton {
      font-size: 14px;
  }

  .controls button {
      font-size: 14px;
      padding: 10px 20px;
  }

  .sectionHeader {
      flex-direction: column;
      align-items: flex-start;
  }

  .sectionHeader span {
      margin-bottom: 8px;
  }

  .sectionActions {
      justify-content: flex-start;
      margin-top: 8px;
  }

  .sectionActions button {
      margin-left: 0;
      margin-right: 16px;
  }

  .item {
      font-size: 14px;
  }

  .itemContainer button {
      font-size: 12px;
  }

  .sectionInput {
      flex-direction: column;
      align-items: stretch;
  }

  .buttonContainer {
      flex-direction: column;
      width: 100%;
  }

  .sectionInput button {
      width: 100%;
      padding: 10px;
      font-size: 14px;
  }
}
