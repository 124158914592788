.sidebar {
    width: 300px;
    min-width: 300px;
    padding: 20px;
    background-color: transparent;
    border: 1px solid lightgray;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
}

.sidebarItem {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    background: transparent;
    cursor: pointer;
    text-align: center;
    color: black;
    user-select: none;
}

.sidebarItem.active {
    background-color: var(--secondary-color);
    color: var(--text-color-dark);
}

.sidebarItem:hover {
    background-color: var(--secondary-color-hover);
}

.sidebarItem:hover:not(.active) {
    color: var(--text-color-dark);
}
