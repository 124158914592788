/* ViewSituationTwo.module.css */

.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: auto;
    overflow: visible;
    align-items: center;
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 50px;
}

.previewPadding {
    padding-top: 100px;
}

.button {
    margin-bottom: 40px;
    padding: 0.75rem 1.5rem;
    background-color: var(--primary-color); /* blue color */
    color: var(--text-color-light);
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* subtle shadow */
    transition: background-color var(--speed) ease;
}

.button:hover {
    background-color: var(--primary-color-hover); /* darker blue on hover */
}

.feedbackButton {
    position: fixed;
    top: var(--navbar-height); /* Adjusted position */
    right: 90px;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease;
    color: var(--primary-color);
    background: none;
    border: none;
    outline: none;
}

.feedbackButton:hover {
    transform: scale(1.1);
    background-color: transparent;
}

.canvasMinimap {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 12vw;
    height: 12vw; /* Ensuring equal width and height */
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    overflow: hidden;
}

.canvasExpanded {
    position: fixed;
    bottom: 10px;
    right: 10px;
    top: 10px;
    left: 10px;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    overflow: hidden;
    z-index: 1000; /* Ensure it's on top of everything else */
    background-color: white;
}

.toggleButton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: var(--primary-color);
    background: none;
    border: none;
    outline: none;
}

