.mainContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.bodyContainer {
    padding-top: var(--navbar-height); /* Padding when the navbar is shown */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    background-color: var(--background-color);
}

/* Class to remove padding-top when the navbar is not displayed */
.noNavbar {
    padding-top: 0; /* Remove padding when the navbar is hidden */
}

:global(html),
:global(body) {
    margin: 0;
    padding: 0;
    max-width: 100%;
    overflow-x: hidden;
}
