.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40px;
    text-align: center;
}

.logo {
    width: 200px;
    margin-bottom: 10px;
}

.container p {
    color: white;
    font-family: var(--font);
}