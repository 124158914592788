/* ScenarioCardOptions.module.css */

.optionsDropdown {
    position: absolute;
    top: 36px;
    right: 8px;
    background: #ffffff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 8px 0;
    z-index: 100;
    min-width: 150px;
}

.dropdownItem {
    padding: 8px 16px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
}

.dropdownItem:hover {
    background-color: #f5f5f5;
}
