.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--my-classrooms-header-height);
    background-color: var(--background-color);
    border-bottom: 1px solid #e0e0e0;
    padding: 0 1em;
    position: fixed;
    width: 100%;
    top: var(--navbar-height);
    left: 0;
    z-index: 9;
}

.title {
    font-size: 24px;
}

.createClassroomButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--background-color);
    color: black;
    border: none;
    padding: 0;
    padding-bottom: 8px;
    font-size: 30px;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    margin-right: 1em;
}

.createClassroomButton:hover {
    background-color: #dedede;
}

.createClassroomButton::after {
    content: "Create a Classroom";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
    white-space: nowrap;
    margin-bottom: 10px;
    transition: opacity 0.3s ease 0.2s;
    z-index: 2000;
}

.createClassroomButton:hover::after {
    opacity: 1;
    transition-delay: 0s;
}
