/* EditSidebar.module.css */

.container {
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 0px;
    box-sizing: border-box;
    border-left: 1px solid #ccc;
}

/* No Element Selected Message */
.noSelection {
    margin: auto;
    text-align: center;
    color: var(--text-color-dark);
    font-size: 18px;
    font-weight: bold;
    opacity: 0.6; /* Optional: make the text a little faded */
}
