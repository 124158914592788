/* ScenarioModal.module.css */

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.fadeIn {
    animation: fadeIn 0.3s ease-out forwards;
}

.fadeOut {
    animation: fadeOut 0.3s ease-in forwards;
}

/* Modal content animation */
.modalContent {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    width: 500px;
    max-width: 90%;
    max-height: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    animation: slideDown 0.3s ease-out forwards;
}

.fadeOut .modalContent {
    animation: slideUp 0.3s ease-in forwards;
}

/* Modal Sections */
.modalHeader,
.modalBody,
.modalFooter {
    padding: 10px 0;
}

/* Modal Title */
.modalTitle {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.searchContainer { 
    margin-bottom: 15px;
}

.searchInput {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.searchInput:focus {
    border-color: var(--primary-color);
    outline: none;
}

/* List */
.list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
}

.item:hover {
    background-color: var(--light-hover-color);
}

.selectedItem {
    background-color: var(--secondary-color);
}

.selectedItem:hover {
    background-color: var(--secondary-color-hover);
}

/* Error Message */
.error {
    color: red;
    margin-top: 10px;
}

/* Modal Footer */
.modalFooter {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
}

/* Button Container */
.buttonContainer {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    width: auto; /* Prevents full width */
}

/* Buttons */
.buttonBase {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.saveButton {
    background-color: var(--primary-color);
    color: #fff;
}

.saveButton:hover {
    background-color: var(--primary-color-hover);
}

.cancelButton {
    background-color: var(--accent-color);
    color: #fff;
}

.cancelButton:hover {
    background-color: var(--accent-color-hover);
}

/* Animations */
@keyframes fadeIn {
    from {
        background: rgba(0, 0, 0, 0);
    }
    to {
        background: rgba(0, 0, 0, 0.5);
    }
}

@keyframes fadeOut {
    from {
        background: rgba(0, 0, 0, 0.5);
    }
    to {
        background: rgba(0, 0, 0, 0);
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-20px);
        opacity: 0;
    }
}
