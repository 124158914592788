/* Base styles for the dropdown */
.dropdownContainer {
    position: fixed;
    left: 0;
    top: calc(var(--navbar-height) + var(--tabsbar-height));
    background-color: var(--secondary-color);
    padding: 20px;
    z-index: 10;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 100vw;
}

/* Center the columns container */
.columnsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center the columns horizontally */
    align-items: flex-start; /* Align columns to the top */
}

/* Styles for each column */
.column {
    width: 250px; /* Set fixed width */
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align content to the left within each column */
}

.column:last-child {
    margin-right: 0;
}

/* Column header */
.columnHeader {
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center; /* Use 'center' or 'flex-start' */
}

.columnHeader div {
    margin-left: 5px;
}

/* List within each column */
.columnList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;         /* Ensure it spans the full width */
}

.columnList li {
    margin-bottom: 5px;
    cursor: pointer;
    text-align: left;    /* Left-align text */
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word;
}

.columnList li:hover {
    text-decoration: underline;
}

/* View All link */
.viewAll {
    margin-top: 20px;
    text-decoration: underline;
    cursor: pointer;
    text-align: center; /* Center the "View All" link */
}

/* Mobile styles */
@media (max-width: 768px) {
    .dropdownContainer {
        position: fixed;
        top: calc(var(--navbar-height) + 60px);
        left: 0;
        width: 100%;
        height: calc(100vh - var(--navbar-height) - 60px);
        overflow-y: auto;
        z-index: 100;
    }

    .mobileHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .mobileHeader div {
        font-size: 20px;
        font-weight: bold;
    }

    .closeIcon {
        font-size: 24px;
        cursor: pointer;
    }

    .columnsContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 20px; /* Add horizontal padding */
    }

    .column {
        width: 100%;          /* Full width on mobile */
        margin-right: 0;
        margin-bottom: 20px;
        align-items: flex-start;
    }

    .columnHeader {
        font-size: 18px;
        cursor: pointer; /* Add cursor pointer on mobile */
    }

    .columnHeader div {
        margin-left: 10px;
    }

    .viewAll {
        margin-top: auto;
        align-self: center;
    }
}
