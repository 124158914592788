.edgeLabel {
    font-size: 12px;
    fill: #000; /* Apparently I need to use 'fill' for SVG text */
    text-anchor: middle;
    alignment-baseline: middle; /* yellow underline cringe, unknown property my ass */
    user-select: none;
}

.edgeLabelSelected {
    fill: #fff;
}

.edgeLabelGroup {
    transition: all 0.3s ease-in-out;
}
