/* Example: a parent container that includes both the toolbar and editable area. */
.ck.ck-editor {
    max-height: 400px;
    width: 100%;
    max-width: 100%;
    min-width: 0;
}

.ck.ck-toolbar.ck-toolbar_grouping {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 10;
}


.ck-dropdown__panel {
    max-height: 200px; /* Adjust as needed */
    overflow-y: auto;
}


.ck.ck-content.ck-editor__editable {
    max-width: 100%;
    min-width: 0;
    overflow-x: auto;
}


