.container {
    margin-top: 30px;
}



.feedbackCard {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
}


.deleteFeedback {
    position: absolute;
    top: 20px;
    right: 23px;
    color: black;
    cursor: pointer;
    font-size: 26px;
    font-weight: bold;
}

.feedbackName {
    font-size: 1.2rem;
    margin-bottom: 8px;
    color: #333;
}

.feedbackDate {
    font-size: 0.9rem;
    color: #777;
    margin-bottom: 8px;
}

.feedbackText {
    font-size: 1rem;
    margin-bottom: 16px;
    color: #555;
}

.situationLink {
    cursor: pointer;
    text-decoration: underline;
}

.situationLinkInactive {
    text-decoration: none;
    cursor: default;
}


.feedbackMessages {
    margin-bottom: 16px;
}

.message {
    margin-bottom: 8px;
}

.messageSender {
    font-weight: bold;
    color: #333;
}

.messageText {
    color: #555;
}

.messageInput {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 8px;
    resize: none;
}

.sendMessageButton {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.sendMessageButton:hover {
    background-color: #45a049;
}

.sentFeedback, .receivedFeedback {
    margin-bottom: 32px;
}

.sentFeedback h2, .receivedFeedback h2 {
    font-size: 1.5rem;
    margin-bottom: 16px;
    color: #333;
}
