.bodyNotMobile {
    padding: 0px 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.bodyMobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
