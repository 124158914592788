.container {
    display: flex;
    height: 98vh;
    width: 100%;
}

.createNodeSidebar {
    height: 100%;
    background-color: var(--background-color);
    border-right: 1px solid #ccc;
    min-width: 300px;
    width: 300px;
}

.canvas {
    height: 100%;
    background-color: #ffffff;
    position: relative;
    flex-grow: 1;
}

.editSidebar {
    height: 100%;
    background-color: var(--background-color);
    border-left: 1px solid #ccc;
    min-width: 300px;
    width: 300px;
}


