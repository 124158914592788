.nodeContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 250px;
    min-width: 120px;
    margin: auto;
    text-align: center;
    cursor: pointer;
}

/* When the node is being dragged */
.nodeContainer:active {
    cursor: grabbing;
}

.nodeContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 50px; /* Without this the node will just shit itself if there's no text */
    min-width: 120px;
    padding: 10px;
    border: 1px solid var(--border-color, #ddd);
    background-color: var(--fill-color, #fff);
    transition: background-color 0.3s, border-color 0.3s;
    word-break: break-word;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}


.nodeContainer .nodeContent > * {
    max-height: 40px;
}

.nodeContainer.selected .nodeContent {
    border-color: var(--primary-color);
    border-width: 2px;
    box-shadow: 0 0 15px 5px rgba(0, 153, 204, 0.6); /* Glowing teal shadow */
    animation: glow 1.5s infinite;
}

@keyframes glow {
    0% {
        box-shadow: 0 0 15px 5px rgba(0, 153, 204, 0.6);
    }
    50% {
        box-shadow: 0 0 20px 10px rgba(0, 153, 204, 0.8);
    }
    100% {
        box-shadow: 0 0 15px 5px rgba(0, 153, 204, 0.6);
    }
}


.nodeContainer.regularNode .nodeContent {
    border-radius: 10px;
}

.nodeContainer.startNode .nodeContent {
    border-radius: 25px;
    border-style: double;
    border-width: 3px;
}

.nodeContainer.endNode .nodeContent {
    border-radius: 0;
    border-style: dashed;
    border-width: 2px;
}


.nodeLabel {
    position: absolute;
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    pointer-events: none;
}

.startNode .nodeLabel {
    color: var(--start-node-label-color, green);
}

.endNode .nodeLabel {
    color: var(--end-node-label-color, red);
}

.handle {
    transition: all 0s ease !important;
    z-index: 10 !important;
}

.handleTarget,
.handleSource {
    width: 12px !important;
    height: 12px !important;
    border-radius: 6px !important;
    transform: translateY(-5px) !important;
}

.handleTarget {
    background: var(--accent-color) !important;
    color: var(--accent-color) !important;
    transform: translateX(-2px) translateY(-5px) !important;
}

.handleTarget:hover {
    background: var(--accent-color-hover) !important;
    color: var(--accent-color-hover) !important;
}

.handleSource {
    background: var(--primary-color) !important;
    color: var(--primary-color) !important;
    transform: translateX(2px) translateY(-5px) !important;
}

.handleSource:hover {
    background: var(--primary-color-hover) !important;
    color: var(--primary-color-hover) !important;
}


/* I think this was for mobile node/edge creation? I think? */
.nodeContainer.edgeConnecting .nodeContent {
    border-color: var(--primary-color);
    border-style: dotted;
    border-width: 2px;
    box-shadow: 0 0 15px 5px rgba(0, 153, 204, 0.6);
    animation: borderAnimation 1.5s infinite;
}

@keyframes borderAnimation {
    0% {
        border-color: var(--primary-color);
    }
    50% {
        border-color: transparent;
    }
    100% {
        border-color: var(--primary-color);
    }
}
