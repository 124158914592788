.classroomItem {
    width: 280px;
    height: 250px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .1);
    position: relative;
    overflow: visible;
    cursor: pointer;
    transition: all 0.2s;
}

.classroomItem:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.classroomImage {
    width: 100%;
    height: 60%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.classroomName {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.8);
    padding: 15px;
    padding-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
    margin: 0 auto;
}

.optionsButton {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    outline: none;
}

.optionsButton:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.optionsButton::before {
    content: '⋮';
    font-size: 30px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.optionsDropdown {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 45px;
    right: -55px;
    width: 100px;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    cursor: pointer;
    background-color: white;
}

.optionsDropdownItem {
    text-align: center;
    width: 100%;
    border-radius: 5px;
}

.optionsDropdownItem:hover {
    background-color: #eeeeee;
}
