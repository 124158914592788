/* ScenarioTitlePage.module.css */
.container {
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 50px 20px;
    box-sizing: border-box;
    overflow-y: auto;
}

.heading {
    margin-bottom: 20px;
    text-align: center;
    font-size: 2em;
  }

.form {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
}

.imageUploadContainer {
    margin: 0 auto 20px auto;
    width: 300px;
    position: relative;
    border: 1px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 300px;
}

.dropzone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.addImageText {
    font-size: 3em;
    color: #ccc;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.imagePreview {
    position: relative;
    width: 100%;
    height: 100%;
}

.imagePreview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.removeImageButton {
    position: absolute;
    background-color: var(--accent-color);
    bottom: 10px;
    right: 10px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 0.9em;
    z-index: 10;
}

.label {
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: bold;
}

.titleInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.titleInput:focus {
    outline: none;
    border-color: var(--primary-color);
}

.charCount {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 10px;
}

.checkboxGroup {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.checkbox {
    margin-right: 10px;
}

.input {
    width: 100%;
    padding: 10px;
    margin-bottom: 30px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.buttonFooter {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.button {
    background-color: var(--primary-color);
    color: var(--text-color-light);
    border: none;
    padding: 12px 25px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.button:hover {
    background-color: var(--primary-color-hover);
}
